import React, {FC} from "react"
import "./Loader.scss"
import Spinner from "./Spinner"

const LoaderInline: FC = () => (
    <div className="loader-inline">
        <div className="loader-body">
            <Spinner/>
        </div>
    </div>
)

export default LoaderInline
