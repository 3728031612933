import React, {FC, useCallback, useEffect, useMemo, useState} from "react"
import "./FoodMatchEditor.scss"
import Loader from "../../_components/Loader/Loader"
import {DocumentsOptions, useDocuments} from "../../_services/hooks.service"
import {TypeCategory, TypeCookingMethod, TypeDish, TypeFoodMatch} from "../../_types/types"
import ErrorHandler from "../../_components/Toast/ErrorHandler"
import {localizedToString} from "../../_helpers/localiztion"
import ky from "ky"
import {headers, headersNoCache} from "../../_helpers/auth-header"
import {useAuth0} from "@auth0/auth0-react"
import {decodeBase64} from "../../_helpers/encoding"
import {MERCURY_URL} from "../../_helpers/environment"
import {useNavigate, useParams} from "react-router-dom"
import {
    ColumnsContainer,
    FormFooter,
    FormWrapper,
    HalfColumn,
    InputContainer,
} from "../../_components/Editor/EditorForm"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import {EditorDescription} from "../../_components/Editor/EditorPage"

const getDishName = (id: string, dishes: any[]) => {
    for (let i = 0; i < dishes.length; i++) {
        if (dishes[i]["@id"] === id)
            return dishes[i].name
    }
    return "[NO NAME]"
}

const fetchFoodMatch = async (id: string, getToken: () => Promise<string>): Promise<TypeFoodMatch> => {
    const t = await getToken()
    return ky.get(id, {"headers": headersNoCache(t)}).json<TypeFoodMatch>()
}

const saveFoodMatch = async (id: string, foodMatch: TypeFoodMatch, getToken: () => Promise<string>) => {
    const t = await getToken()
    let f = ky.post
    let url = `${MERCURY_URL}/food-matches`
    if (id) {
        f = ky.put
        url = id
    }
    await f(url, {
        "json": foodMatch,
        "headers": headers(t),
    })
}

const FoodMatchEditor: FC = () => {

    const navigate = useNavigate()
    const {"id": urlId} = useParams<{ id: string }>()
    const id = (urlId !== "create") ? decodeBase64(urlId || "") : ""

    const options = useMemo<DocumentsOptions>(() => ({
        "size": 100,
    }), [])
    const {"documents": dishes, "isLoading": l1, "error": e1} = useDocuments<TypeDish>("/dishes", options)
    const {
        "documents": cookingMethods,
        "isLoading": l2,
        "error": e2,
    } = useDocuments<TypeCookingMethod>("/cooking-methods", options)
    const {"documents": wineStyles, "isLoading": l3, "error": e3} = useDocuments<TypeCategory>("/categories", options)
    const {
        "documents": foodMatches,
        "isLoading": l4,
        "error": e4,
    } = useDocuments<TypeFoodMatch>("/food-matches", options)

    const {getAccessTokenSilently} = useAuth0()
    const [busy, setBusy] = useState(true)
    const [error, setError] = useState<any>()
    const [foodMatch, setFoodMatch] = useState<TypeFoodMatch>({
        "@id": "",
        "@type": "FoodMatch",
        "name": "[NO DISH SELECTED]",
        "dish": {"@id": ""},
        "matches": [],
    })

    const save = useCallback(() => {
        setBusy(true)
        saveFoodMatch(id, foodMatch, getAccessTokenSilently)
            .then(() => navigate("/food-pairing/food-matches"))
            .catch(e => setError(e))
            .finally(() => setBusy(false))
    }, [navigate, id, foodMatch, getAccessTokenSilently])

    useEffect(() => {
        if (id === "") {
            setBusy(false)
            return
        }
        setBusy(true)
        fetchFoodMatch(id, getAccessTokenSilently)
            .then(r => setFoodMatch(r))
            .catch(e => setError(e))
            .finally(() => setBusy(false))
    }, [id, getAccessTokenSilently])

    const existingFoodMatchDishes = useMemo<string[]>(() => {
        const results: string[] = []
        foodMatches.forEach(fm => {
            if (fm["@id"] !== foodMatch["@id"] && fm.dish !== null) {
                results.push(fm.dish["@id"])
            }
        })
        return results
    }, [foodMatches, foodMatch])

    if (e1 || e2 || e3 || e4 || error) {
        return (
            <ErrorHandler error={e1 || e2 || e3 || error}/>
        )
    }

    if (l1 || l2 || l3 || l4 || busy) return <Loader/>

    return (
        <form onSubmit={e => {
            e.preventDefault()
            save()
        }}>
            <div className='page-header'>
                <h1>Food Match</h1>
            </div>
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Dish"}>
                            <select
                                onChange={e => {
                                    const v = e.target.value
                                    setFoodMatch((prev: any) => ({
                                        ...prev,
                                        "name": getDishName(v, dishes),
                                        "dish": {...prev.dish, "@id": v},
                                    }))
                                }}
                                value={foodMatch.dish?.["@id"] || ""}
                                className="form-input"
                                id="dishInput"
                            >
                                <option value={""}> - Select a dish -</option>
                                {dishes.filter(dish => !existingFoodMatchDishes.includes(dish["@id"])).map(dish => {
                                    return <option key={dish["@id"]}
                                                   value={dish["@id"]}>{localizedToString(dish.name)}</option>
                                })}
                            </select>
                            <EditorDescription>
                                If no dishes are listed create one under <i>Food Pairing &gt; Dishes</i> before filling
                                in the matching table.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
            <FormWrapper>
                <table className="table table-scroll food-match-table">
                    <thead>
                    <tr>
                        <td/>
                        {cookingMethods.map(cm => {
                            return (
                                <td key={cm["@id"]}>
                                    <span>
                                    {localizedToString(cm["name"]).replace("/", " ")}
                                    </span>
                                </td>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {wineStyles.map(ws => (
                        <tr key={ws["@id"]}>
                            <td>
                                {localizedToString(ws.name)}
                            </td>
                            {cookingMethods.map(cm => (
                                <td key={cm["@id"] + ":" + ws["@id"]}>
                                    <div className="check-container">
                                        <label className="form-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={foodMatch.matches.map((m: any) => {
                                                    return m.cookingMethod["@id"].split("/").pop() === cm["@id"].split("/").pop() &&
                                                        m.recommendation["@id"].split("/").pop() === ws["@id"].split("/").pop()

                                                }).some((v: boolean) => v)}
                                                onChange={e => {
                                                    const v = e.target.checked
                                                    if (!v) {
                                                        const items = foodMatch.matches.filter((m: any) => {
                                                            return !(m.cookingMethod["@id"].split("/").pop() === cm["@id"].split("/").pop() &&
                                                                m.recommendation["@id"].split("/").pop() === ws["@id"].split("/").pop())
                                                        })
                                                        setFoodMatch((prev: any) => ({
                                                            ...prev, "matches": items,
                                                        }))
                                                    } else {
                                                        setFoodMatch((prev: any) => ({
                                                            ...prev, "matches": [...foodMatch.matches, {
                                                                "@type": "FoodMatchPair",
                                                                "recommendation": {"@id": ws["@id"]},
                                                                "cookingMethod": {"@id": cm["@id"]},
                                                            }],
                                                        }))
                                                    }
                                                }}
                                            />
                                            <i className="form-icon"/>
                                        </label>
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </FormWrapper>
            <FormFooter>
                <button className="btn btn-primary mt-2 mr-2" type="submit">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>
                    {(id === "") ? "Save as New" : "Save Changes"}
                </button>
                <button onClick={() => navigate("/food-pairing/food-matches")} className="btn mt-2">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2"/>
                    Discard Changes
                </button>
            </FormFooter>
        </form>
    )
}

export default FoodMatchEditor
