import React, {FC, useEffect} from "react"

interface Props {
    id: string // unique id for page
    label?: string
    value: string
    onChange: (value: string) => void
    options: { [key: string]: string } // list of option values
    hint?: string // shows message
    success?: boolean // shows hint in green
    invalid?: boolean // shows hint in red
    required?: boolean
}


export const FormSelectString: FC<Props> = props => {
    useEffect(() => {
        if (props.value === undefined) {
            props.onChange("")
        }
    }, [props])
    return (
        <div className={`form-group ${props.success ? "has-success" : ""} ${props.invalid ? "has-error" : ""}`}>
            {props.label ? <label className="form-label" htmlFor={props.id}>{props.label}</label> : null}
            <select
                value={props.value || ""}
                required={props.required}
                className="form-select"
                onChange={e => {
                    const k = e.target.value
                    props.onChange(k)
                }}
            >
                {Object.keys(props.options).map(o => <option key={o} value={o}>{props.options[o]}</option>)}
            </select>
            {props.hint ? <p className="form-input-hint mb-0">{props.hint}</p> : null}
        </div>
    )
}

interface PropsObject<T> {
    id: string // unique id for page
    label?: string
    value: T
    onChange: (value: T) => void
    options: T[] // list of option values
    getName: (option: T) => string // renders option text
    getValue: (options: T) => string
    hint?: string // shows message
    success?: boolean // shows hint in green
    invalid?: boolean // shows hint in red
    required?: boolean
}

export const FormSelect = <T extends unknown>(props: PropsObject<T>) => (
    <div className={`form-group ${props.success ? "has-success" : ""} ${props.invalid ? "has-error" : ""}`}>
        {props.label ? <label className="form-label" htmlFor={props.id}>{props.label}</label> : null}
        <select
            value={props.getValue(props.value)}
            required={props.required}
            className="form-select"
            onChange={e => {
                const v = e.target.value
                let option = undefined
                for (let i = 0; i < props.options.length; i++) {
                    if (props.getValue(props.options[i]) === v) {
                        option = props.options[i]
                        break
                    }
                }
                if (option !== undefined) {
                    props.onChange(option)
                }
            }}
        >
            {props.options.map(o => (
                <option key={props.getValue(o)} value={props.getValue(o)}>{props.getName(o)}</option>
            ))}
        </select>
        {props.hint ? <p className="form-input-hint mb-0">{props.hint}</p> : null}
    </div>
)
