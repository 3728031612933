import React, {FC} from "react"
import "./AuthenticationPage.scss"
import {useAuth0} from "@auth0/auth0-react"
import {encodeBase64} from "../_helpers/encoding"
import {useLocation} from "react-router-dom"

const AuthenticationPage: FC = () => {

    const {loginWithRedirect} = useAuth0()
    const {pathname} = useLocation()

    return (
        <div id="authentication">
            <div className="auth-container container grid-md">
                <div style={{"flexGrow": 1}}/>
                <div className="text-center auth-body">
                    <div className="company-logo"/>
                    <button
                        className="btn btn-authenticate"
                        onClick={() => {
                            const pathParts = pathname.split("/")
                            pathParts.splice(0, 1)
                            const pathNoLang = "/" + pathParts.join("/")
                            loginWithRedirect({
                                "redirectUri": `${window.location.origin}/authenticate?r=${encodeBase64(pathNoLang)}`,
                            })
                        }}
                    >
                        Authenticate
                    </button>
                </div>
                <div className="dust-lake-logo"/>
            </div>
        </div>
    )
}

export default AuthenticationPage
