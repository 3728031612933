import {TypeThing} from "../_types/types"
import ky from "ky"
import {MERCURY_URL} from "../_helpers/environment"
import {headersNoCache} from "../_helpers/auth-header"

export const fetchWithToken = async <T extends TypeThing>(id: string, getToken: () => Promise<string>): Promise<T> => {
    const t = await getToken()
    return await ky.get(`${id}`, {"headers": headersNoCache(t)}).json<T>()
}

interface ListResult<T> {
    headers: Headers
    body: T[]
}

export const fetchListWithToken = async <T extends TypeThing>(endpoint: string, getToken: () => Promise<string>): Promise<ListResult<T>> => {
    const t = await getToken()
    const result = await ky.get(`${MERCURY_URL}${endpoint}`, {"headers": headersNoCache(t)})
    const body: { "@graph": T[] } = await result.json()
    return {
        "headers": result.headers,
        "body": body["@graph"],
    }
}
