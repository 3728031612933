import React, {FC, useEffect, useMemo, useState} from "react"
import {TypeLocalizedLiteral} from "../../_types/types"
import {useLanguages} from "../../_services/hooks.service"
import LoaderSmall from "../Loader/LoaderSmall"
import TextareaAutosize from "react-textarea-autosize"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"

interface Props {
    value: TypeLocalizedLiteral
    onChange: (v: TypeLocalizedLiteral) => void
    id?: string
    textarea?: boolean
    placeholder?: string
    minRows?: number
}

const getValueByLanguage = (d: TypeLocalizedLiteral, lang: string): string => {
    for (let i = 0; i < d.length; i++) {
        if (d[i]["@language"] === lang) {
            return d[i]["@value"]
        }
    }
    return ""
}

const setValueByLanguage = (v: string, d: TypeLocalizedLiteral, lang: string): TypeLocalizedLiteral => {
    for (let i = 0; i < d.length; i++) {
        if (d[i]["@language"] === lang) {
            d[i]["@value"] = v
            return [...d]
        }
    }
    d.push({"@value": v, "@language": lang})
    return [...d]
}

const cleanValueByLanguage = (d1: TypeLocalizedLiteral, languages: string[]): TypeLocalizedLiteral => {
    const d2: TypeLocalizedLiteral = []
    for (let i = 0; i < d1.length; i++) {
        if (languages.includes(d1[i]["@language"]) && d1[i]["@value"] !== "") {
            d2.push(d1[i])
        }
    }
    return d2
}

const FormLocalizedInput: FC<Props> = ({value, onChange, textarea, placeholder, minRows}) => {

    const [language, setLanguage] = useState<string>("")
    const {languages, isLoading, error} = useLanguages()
    const languageCodes = useMemo(() => ["", ...languages.map(l => l.alternateName)], [languages])

    useEffect(() => {
        if (value.length === 0) {
            onChange([{"@value": "", "@language": ""}])
        }
    }, [onChange, value])

    useEffect(() => {
        if (value === undefined) {
            onChange([])
        }
    }, [onChange, value])

    if (isLoading || value === undefined) {
        return <LoaderSmall/>
    }

    if (error) {
        return <div className="toast toast-error">{error.toString()}</div>
    }

    return (
        <div>
            {textarea ? (
                <TextareaAutosize
                    placeholder={placeholder}
                    className="form-input form-input-resize"
                    minRows={minRows || 1}
                    value={getValueByLanguage(value, language)}
                    onChange={e => {
                        const v = e.target.value
                        let l = setValueByLanguage(v, value, language)
                        l = cleanValueByLanguage(l, languageCodes)
                        onChange(l)
                    }}
                />
            ) : (
                <input
                    type="text"
                    className="form-input"
                    value={getValueByLanguage(value, language)}
                    onChange={e => {
                        const v = e.target.value
                        let l = setValueByLanguage(v, value, language)
                        l = cleanValueByLanguage(l, languageCodes)
                        onChange(l)
                    }}
                />
            )}
            <LanguageSwitcher
                languageCodes={languageCodes}
                setLanguage={setLanguage}
                language={language}
                value={value}
            />
        </div>
    )
}

interface LanguageSwitcherProps {
    languageCodes: string[]
    value: TypeLocalizedLiteral
    language: string
    setLanguage: (v: string) => void
}

const LanguageSwitcher: FC<LanguageSwitcherProps> = React.memo((props) => (
    <div className="mt-1">
        {props.languageCodes.map(lang => {
            const isEmpty = getValueByLanguage(props.value, lang).length === 0
            return (
                <button
                    key={lang}
                    onClick={() => props.setLanguage(lang)}
                    type="button"
                    style={props.language === lang ? {"textDecoration": "underline"} : {}}
                    className={`btn btn-link ${props.language === lang ? "disabled" : ""}`}
                >
                    {lang === "" ? "default" : lang}
                    {isEmpty ? null : (
                        <FontAwesomeIcon
                            style={{"fontSize": "0.45rem", "marginBottom": "2px"}}
                            icon={faCheck}
                            className="ml-1"
                        />
                    )}
                </button>
            )
        })}
    </div>
))

export default FormLocalizedInput
