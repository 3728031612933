import React, {FC, useCallback, useState} from "react"
import {EmbeddedImageObject} from "../../_types/types"
import Dropzone from "react-dropzone"
import "./FormImageInput.scss"
import LoaderSmall from "../Loader/LoaderSmall"
import ky from "ky"
import {NEPTUNE_URL} from "../../_helpers/environment"
import {headers} from "../../_helpers/auth-header"
import {useAuth0} from "@auth0/auth0-react"
import ErrorHandler from "../Toast/ErrorHandler"

interface Props {
    fullSize?: string
    thumbSize?: string
    onChange: (value: EmbeddedImageObject | null) => void
}

const uploadImage = async (image: File, getToken: () => Promise<string>, fullSize?: string, thumbSize?: string): Promise<EmbeddedImageObject> => {
    const token = await getToken()
    const formData = new FormData()
    formData.append("image", image, image.name)
    formData.append("full_size", fullSize || "1000x1000")
    formData.append("thumb_size", thumbSize || "300x300")
    return await ky.post(`${NEPTUNE_URL}/images`, {
        "body": formData,
        "headers": headers(token),
    }).json<EmbeddedImageObject>()
}

const FormImageInput: FC<Props> = ({onChange, fullSize, thumbSize}) => {

    const [hover, setHover] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const {getAccessTokenSilently} = useAuth0()
    const [error, setError] = useState<any>()

    const process = useCallback((files: File[]) => {
        setLoading(true)
        setHover(false)
        uploadImage(files[0], getAccessTokenSilently, fullSize, thumbSize)
            .then(result => {
                onChange(result)
                setError(undefined)
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [fullSize, thumbSize, getAccessTokenSilently, onChange])

    return (
        <div>
            <div
                className={`image-drop ${isLoading ? "image-loading" : ""} ${hover ? "hover" : ""}`}>
                {isLoading ? (
                    <LoaderSmall/>
                ) : (
                    <Dropzone
                        onDrop={process}
                        onDragEnter={() => setHover(true)}
                        onDragLeave={() => setHover(false)}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div className="dropzone-input" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <span className="dropzone-label">Click to upload</span>
                            </div>
                        )}
                    </Dropzone>
                )}
            </div>
            <ErrorHandler error={error}/>
        </div>
    )

}


export default FormImageInput
