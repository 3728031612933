import React, {useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import "./Header.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons"
import SideMenu from "../SideMenu/SideMenu"

const Header = () => {

    const {logout, user} = useAuth0()

    const [showMenu, setShowMenu] = useState<boolean>(false)

    return (
        <header className="navbar" id="header">
            {showMenu ? (
                <div className="menu-mobile">
                    <button className="btn btn-menu-close" onClick={() => setShowMenu(false)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                    <SideMenu onClick={() => setTimeout(() => setShowMenu(false), 100)}/>
                </div>
            ) : null}
            <section className="navbar-section">
                <div className="company-logo hide-md"/>
                <button className="btn-header-menu btn show-md" onClick={() => setShowMenu(true)}>
                    <FontAwesomeIcon icon={faBars} className="mr-2"/>
                    Menu
                </button>
                <div className="company-name hide-md">
                    Matthys Wines
                    <span className="user-name-div hide-lg">|</span>
                    <span className="user-name hide-lg">{user?.firstName || user?.email}</span>
                </div>
            </section>

            <section className="navbar-section navbar-center show-md">
                <div className="company-logo-mobile"/>
            </section>
            <section className="navbar-section">
                <button
                    className="btn btn-navbar"
                    onClick={() => logout({"returnTo": `${window.location.origin}/sign-out`})}>
                    Sign out
                </button>
            </section>
        </header>
    )
}

export default Header
