import ky from "ky"
import {MERCURY_URL} from "../_helpers/environment"
import {headersNoCache} from "../_helpers/auth-header"
import {useAuth0} from "@auth0/auth0-react"
import {TypeLanguage, TypeThing} from "../_types/types"
import {useEffect, useState} from "react"
import {fetchListWithToken, fetchWithToken} from "./mercury.service"
import {Mutex} from "async-mutex"

interface LanguagesProps {
    isLoading: boolean
    languages: TypeLanguage[]
    error: any
}

let languageCache: TypeLanguage[] = []
let languageHasCached = false
const languageLock = new Mutex()
const fetchLanguages = async (getToken: () => Promise<string>): Promise<TypeLanguage[]> => {
    const release = await languageLock.acquire()
    if (!languageHasCached) {
        const t = await getToken()
        const result = await ky.get(`${MERCURY_URL}/languages?page[size]=10`, {"headers": headersNoCache(t)})
            .json<{ "@graph": TypeLanguage[] }>()
        languageCache = result["@graph"]
        languageHasCached = true
    }
    release()
    return languageCache
}

export const useLanguages = (): LanguagesProps => {

    const {getAccessTokenSilently} = useAuth0()
    const [languages, setLanguages] = useState<TypeLanguage[]>([])
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState<any>()

    useEffect(() => {
        let cancelled = false
        setLoading(true)
        fetchLanguages(getAccessTokenSilently)
            .then(result => !cancelled && setLanguages(result))
            .catch(e => !cancelled && setError(e))
            .finally(() => !cancelled && setLoading(false))
        return () => {
            cancelled = true
        }
    }, [getAccessTokenSilently])

    return {
        error,
        languages,
        isLoading,
    }
}

export const useDocument = <T extends TypeThing>(id: string): {
    isLoading: boolean
    document: T | null
    error: any
} => {

    const {getAccessTokenSilently} = useAuth0()
    const [sequence, setSequence] = useState<number>(-1)
    const [document, setResult] = useState<T | null>(null)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState<any>()

    useEffect(() => {
        setSequence(p => p + 1)
    }, [id])

    useEffect(() => {
        if (sequence < 0) {
            return
        }
        if (!id) {
            setLoading(false)
            setError(undefined)
            setResult(null)
        }
        let cancelled = false
        let current = sequence
        setLoading(true)
        fetchWithToken<T>(id, getAccessTokenSilently)
            .then(res => {
                if (!cancelled && current === sequence) {
                    setError(null)
                    setResult(res)
                    setLoading(false)
                }
            })
            .catch(e => {
                if (!cancelled && current === sequence) {
                    setError(e)
                    setLoading(false)
                }
            })
        return () => {
            cancelled = true
        }
    }, [getAccessTokenSilently, sequence, id])

    return {
        isLoading,
        document,
        error,
    }
}

export interface DocumentsOptions {
    onlySearchOnQuery?: boolean
    filter?: { [key: string]: string }
    delay?: number
    page?: number
    size?: number
    sort?: string[]
}

export const useDocuments = <T extends TypeThing>(endpoint: string, options?: DocumentsOptions): {
    isLoading: boolean
    documents: T[]
    error: any
    hasNextPage: boolean
} => {

    const {getAccessTokenSilently} = useAuth0()
    const [sequence, setSequence] = useState<number>(-1)
    const [documents, setResults] = useState<T[]>([])
    const [hasNextPage, setHasNextPage] = useState<boolean>(false)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState<any>()

    useEffect(() => {
        setSequence(p => p + 1)
    }, [options, endpoint])

    useEffect(() => {
        if (sequence < 0) {
            return
        }

        // Only Search On Query rule
        if (options && options.onlySearchOnQuery) {
            if (options.filter && Object.values(options.filter).filter(v => v.length > 0).length === 0) {
                setLoading(false)
                setError(undefined)
                setResults([])
                return
            }
        }

        let query: string[] = []

        // prepare filter query
        if (options && options.filter) {
            const items: string[] = []
            for (let key in options.filter) {
                if (!options.filter.hasOwnProperty(key)) {
                    continue
                }
                if (options.filter[key].length === 0) {
                    continue
                }
                items.push(`filter[${key}]=/${options.filter[key].toLowerCase()}/`)
            }
            query.push(items.join("&"))
        }

        // prepare sorting query
        if (options && options.sort) {
            query.push(`sort=${options.sort.join(",")}`)
        }

        // prepare size and limit query
        if (options && options.size !== undefined) {
            query.push(`page[size]=${options.size}`)
        }
        if (options && options.page !== undefined) {
            query.push(`page[number]=${options.page}`)
        }

        let cancelled = false
        let current = sequence
        setLoading(true)
        let e = endpoint + "?" + encodeURI(query.join("&"))

        // low delay for first request
        let delay = 50
        if (options && options.delay) {
            delay = options.delay
        }

        setTimeout(() => {
            if (cancelled || current !== sequence) {
                return
            }
            fetchListWithToken<T>(e, getAccessTokenSilently)
                .then(res => {
                    if (!cancelled && current === sequence) {
                        setError(null)
                        setResults(res.body)
                        const link = res.headers.get("Link")
                        setHasNextPage(link !== null && link.indexOf("next") !== -1)
                        setLoading(false)
                    }
                })
                .catch(e => {
                    if (!cancelled && current === sequence) {
                        setError(e)
                        setLoading(false)
                    }
                })
        }, delay)

        return () => {
            cancelled = true
        }
    }, [getAccessTokenSilently, sequence, endpoint, options])

    return {
        isLoading,
        documents,
        error,
        hasNextPage,
    }
}
