import React, {FC} from "react"
import {Link} from "react-router-dom"
import "./SideMenu.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faCheese,
    faDollarSign,
    faFeatherAlt,
    faGlobeEurope,
    faHome, faPlateWheat,
    faTag,
    faUser, faUtensils,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons"

interface MenuEntryProps {
    name: string
    to: string
    onClick?: any
}

const MenuEntry: FC<MenuEntryProps> = ({name, to, onClick}) => {
    return (
        <li className="nav-item nav-item-link">
            <Link to={to} onClick={() => onClick && onClick()}>{name}</Link>
        </li>
    )
}

interface MenuHeaderProps {
    name: string
    icon: IconDefinition
}

const MenuHeader: FC<MenuHeaderProps> = ({name, icon}) => {
    return (
        <>
            <FontAwesomeIcon icon={icon} className="nav-icon"/>
            {name}
        </>
    )
}

interface MenuSectionProps {
    id: string
    header: React.ReactNode
    items: React.ReactNode
}

const MenuSection: FC<MenuSectionProps> = ({id, header, items}) => (
    <div className="accordion">
        <input type="checkbox" id={id} name="accordion-checkbox" hidden/>
        <label className="accordion-header nav-header" htmlFor={id}>
            {header}
        </label>
        <ul className="nav accordion-body">
            {items}
        </ul>
    </div>
)

const SideMenu: FC<{ onClick?: any }> = ({onClick}) => (
    <div id='side-menu'>
        <Link to={"/"} className="nav-header nav-dashboard">
            <FontAwesomeIcon icon={faHome} className="nav-icon"/>
            Dashboard
        </Link>
        <MenuSection
            id={"accordion-transactional"}
            header={
                <MenuHeader name={"Transactional"} icon={faDollarSign}/>
            }
            items={
                <>
                    <MenuEntry onClick={onClick} name={"Orders"} to={"/transactional/orders"}/>
                    <MenuEntry onClick={onClick} name={"Payment Methods"} to={"/transactional/payment-methods"}/>
                    <MenuEntry onClick={onClick} name={"Shipping Costs"} to={"/transactional/shipping-costs"}/>
                </>
            }
        />
        <MenuSection
            id={"accordion-mailing"}
            header={
                <MenuHeader name={"Mailing"} icon={faUser}/>
            }
            items={
                <>
                    <MenuEntry onClick={onClick} name={"Addresses"} to={"/customers/subscriptions"}/>
                </>
            }
        />
        <MenuSection
            id={"accordion-horeca"}
            header={
                <MenuHeader name={"Horeca"} icon={faUtensils}/>
            }
            items={
                <>
                    <MenuEntry onClick={onClick} name={"Orders"} to={"/horeca/orders"}/>
                    <MenuEntry onClick={onClick} name={"Members"} to={"/horeca/members"}/>
                </>
            }
        />
        <MenuSection
            id={"accordion-content"}
            header={
                <MenuHeader name={"Content"} icon={faFeatherAlt}/>
            }
            items={
                <>
                    <MenuEntry onClick={onClick} name={"Articles"} to={"/content/articles"}/>
                    <MenuEntry onClick={onClick} name={"Wine Lists"} to={"/content/wine-lists"}/>
                    <MenuEntry onClick={onClick} name={"Wiki Categories"} to={"/content/wiki-categories"}/>
                    <MenuEntry onClick={onClick} name={"Wiki Articles"} to={"/content/wiki-articles"}/>
                </>
            }
        />
        <MenuSection
            id={"accordion-product"}
            header={
                <MenuHeader name={"Product"} icon={faTag}/>
            }
            items={
                <>
                    <MenuEntry onClick={onClick} name={"Wines"} to={"/product/wines"}/>
                    <MenuEntry onClick={onClick} name={"Discovery Cases"} to={"/product/discovery-cases"}/>
                    <MenuEntry onClick={onClick} name={"Categories"} to={"/product/categories"}/>
                    <MenuEntry onClick={onClick} name={"Grapes"} to={"/product/grapes"}/>
                    <MenuEntry onClick={onClick} name={"Agriculture"} to={"/product/agriculture"}/>
                    <MenuEntry onClick={onClick} name={"Bottle Closures"} to={"/product/bottle-caps"}/>
                    <MenuEntry onClick={onClick} name={"Ageings"} to={"/product/agings"}/>
                    <MenuEntry onClick={onClick} name={"Countries"} to={"/product/countries"}/>
                    <MenuEntry onClick={onClick} name={"Appellations"} to={"/product/appellations"}/>
                    <MenuEntry onClick={onClick} name={"Vignerons"} to={"/product/manufacturers"}/>
                </>
            }
        />
        <MenuSection
            id={"accordion-food-pairing"}
            header={
                <MenuHeader name={"Food Pairing"} icon={faCheese}/>
            }
            items={
                <>
                    <MenuEntry onClick={onClick} name={"Food Matching"} to={"/food-pairing/food-matches"}/>
                    <MenuEntry onClick={onClick} name={"Dishes"} to={"/food-pairing/dishes"}/>
                    <MenuEntry onClick={onClick} name={"Food Types"} to={"/food-pairing/food-types"}/>
                    <MenuEntry onClick={onClick} name={"Cooking Methods"} to={"/food-pairing/cooking-methods"}/>
                </>
            }
        />
        <MenuSection
            id={"accordion-localization"}
            header={
                <MenuHeader name={"Localization"} icon={faGlobeEurope}/>
            }
            items={
                <>
                    <MenuEntry onClick={onClick} name={"Translations"} to={"/localization/translations"}/>
                    <MenuEntry onClick={onClick} name={"Languages"} to={"/localization/languages"}/>
                </>
            }
        />
        <div style={{"height": "4rem"}}/>
    </div>
)

export default SideMenu
