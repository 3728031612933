import React, {FC} from "react"

interface InputContainerProps {
    title: string
    lead?: string
    children?: React.ReactNode
}

export const InputContainer: FC<InputContainerProps> = ({title, lead, children}) => (
    <div className="columns">
        <div className="column col-3">
            <div className="form-label editor-label">
                {title}
            </div>
            {lead ? <div className="form-label-lead">{lead}</div> : null}
        </div>
        <div className="column col-9">
            {children}
        </div>
    </div>
)

export const InputLabel: FC<{ title: string }> = ({title}) => (
    <div className="form-label editor-label">
        {title}
    </div>
)

export const InputDivider: FC = () => (
    <div className="page-divider"/>
)

type PageBlock = {
    children?: React.ReactNode
}

export const FormWrapper: FC<PageBlock & { className?: string }> = ({children, className}) => (
    <div className={`editor-page ${className ? className : ""}`}>
        {children}
    </div>
)

export const HalfColumn: FC<PageBlock & { className?: string }> = ({children, className}) => (
    <div className={`column col-6 col-lg-12 ${className ? className : ""}`}>
        {children}
    </div>
)

export const ColumnsContainer: FC<PageBlock> = ({children}) => (
    <div className="container px-0">
        <div className="columns">
            {children}
        </div>
    </div>
)

export const FormHeader: FC<PageBlock> = ({children}) => (
    <div className='editor-float'>
        {children}
    </div>
)

export const FormFooter: FC<PageBlock> = ({children}) => (
    <div className='editor-footer'>
        {children}
    </div>
)
