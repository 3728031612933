import React, {FC} from "react"
import {useAuth0} from "@auth0/auth0-react"
import Loader from "../_components/Loader/Loader"
import AuthenticationPage from "./AuthenticationPage"

const PrivateRoute: FC<{ title?: string, children?: React.ReactNode }> = ({children, title}) => {
    const {isAuthenticated, isLoading} = useAuth0()
    if (isLoading) {
        return <Loader/>
    } else if (isAuthenticated) {
        return <>{children}</>
    } else {
        return (
            <AuthenticationPage/>
        )
    }
}

export default PrivateRoute
