import React, {FC} from "react"
import "./Footer.scss"

const Footer: FC = () => {
    return (
        <footer>
            <div className="dust-lake-logo"/>
        </footer>
    )
}

export default Footer
