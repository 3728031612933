import React, {FC, useCallback, useEffect, useState} from "react"
import ky from "ky"
import * as base64 from "base-64"
import "./SubscriptionListPage.scss"
import {API_URL} from "../../_helpers/environment"
import Loader from "../../_components/Loader/Loader"
import {useAuth0} from "@auth0/auth0-react"
import {headersNoCache} from "../../_helpers/auth-header"
import {ColumnsContainer, FormWrapper, HalfColumn} from "../../_components/Editor/EditorForm"

type CustomerEntry = {
    status: string
    customer: {
        email: string
        "@type": string
    }
    subscriptions: {}
    creation: string
}

export enum CustomerType {
    Organization = "Organization",
    Person = "Person"
}

interface FormProps {
    onDone: (success: boolean) => void
    excluded: string[]
}

const pushSubscription = async (exists: boolean, kind: string, email: string, getToken: () => Promise<string>) => {
    const t = await getToken()
    if (exists) {
        await ky.put(`${API_URL}/subscriptions/${base64.encode(email)}`, {
            "headers": headersNoCache(t),
            "json": {
                "status": "valid",
                "type": kind,
            },
        })
    } else {
        await ky.post(`${API_URL}/subscriptions`, {
            "headers": headersNoCache(t),
            "json": {
                email,
                "type": kind,
            },
        })
    }
}

const AddSubscriptionForm: FC<FormProps> = ({onDone, excluded}) => {

    const {getAccessTokenSilently} = useAuth0()
    const [email, setEmail] = useState<string>("")
    const [busy, setBusy] = useState<boolean>(false)
    const [customerType, setCustomerType] = useState<string>(CustomerType.Person)
    const [error, setError] = useState<any>()
    const [exists, setExists] = useState<boolean>(false)

    useEffect(() => {
        if (excluded.includes(email)) {
            setExists(true)
        } else {
            setExists(false)
        }
    }, [excluded, email])

    const submit = useCallback(() => {
        setBusy(true)
        pushSubscription(exists, customerType, email, getAccessTokenSilently)
            .then(() => onDone(true))
            .catch(err => setError(err))
            .finally(() => setBusy(false))
    }, [getAccessTokenSilently, customerType, email, exists, onDone])

    return (
        <div className="modal active" id="modal-id">
            {busy ? <Loader/> : null}
            <div className="modal-overlay"/>
            <div className="modal-container">
                <form onSubmit={e => {
                    e.preventDefault()
                    submit()
                }}>
                    <div className="modal-header">
                        Add Subscription
                        <button className="btn btn-clear float-right" type="button" onClick={() => onDone(false)}/>
                    </div>
                    <div className="modal-body">
                        <div className={"form-group " + (exists ? "has-success" : "")}>
                            <label className="form-label">Email</label>
                            <input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type="email"
                                className="form-input"
                                placeholder=""
                            />
                            <p className="form-input-hint mb-0">{exists ? "Existing email will be overwritten" : null}</p>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Type</label>
                            <select
                                className="form-input"
                                value={customerType}
                                onChange={e => setCustomerType(e.target.value)}
                            >
                                <option value={CustomerType.Person}>Person</option>
                                <option value={CustomerType.Organization}>Organization</option>
                            </select>
                        </div>
                        {error ? <div className="toast toast-error mt-2">{error.toString()}</div> : null}
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary input-group-btn"
                            type={"submit"}
                        >{exists ? "Update" : "Add"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

interface TableProps {
    customers: CustomerEntry[],
    fetch?: () => void,
    filter: (c: CustomerEntry) => boolean
}

const deleteSubscription = async (email: string, getToken: () => Promise<string>) => {
    const t = await getToken()
    await ky.delete(`${API_URL}/subscriptions/${base64.encode(email)}`, {
        "headers": headersNoCache(t),
    })
}

const SubscriptionTable: FC<TableProps> = React.memo(({filter, customers, fetch}) => {

    const {getAccessTokenSilently} = useAuth0()
    const [error, setError] = useState<any>()
    const filtered = customers.filter(filter)

    const del = useCallback((email: string) => {
        deleteSubscription(email, getAccessTokenSilently)
            .catch(error => setError(error))
            .finally(() => fetch && fetch())
    }, [fetch, getAccessTokenSilently])

    return (
        <>
            <table className="subscriptions-table">
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Added on</th>
                    <th>Subscribed</th>
                    {fetch ? <th/> : null}
                </tr>
                </thead>
                <tbody>
                {filtered?.map((entry, i) => (
                    <tr key={entry.customer.email + i}>
                        <td>{entry.customer.email}</td>
                        <td>{entry.customer["@type"]}</td>
                        <td>{entry.creation.split("T")[0]}</td>
                        <td>{fetch ? "Yes" : "No"}</td>
                        {fetch ? (
                            <td>
                                <button className="btn btn-link" onClick={() => del(entry.customer.email)}>
                                    Delete
                                </button>
                            </td>
                        ) : null}
                    </tr>
                ))}
                </tbody>
            </table>
            {error ? <div className="toast toast-error mt-2">{error.toString()}</div> : null}
        </>
    )
})

const fetchSubscriptions = async (getToken: () => Promise<string>): Promise<CustomerEntry[]> => {
    const t = await getToken()
    const result = await ky.get(`${API_URL}/subscriptions`, {"headers": headersNoCache(t)}).json<CustomerEntry[]>()
    result.sort((a, b) => {
        if (a.customer["@type"] !== b.customer["@type"]) {
            return (a.customer["@type"] > b.customer["@type"]) ? -1 : 1
        } else {
            return (a.customer.email.toLowerCase() < b.customer.email.toLocaleLowerCase()) ? -1 : 1
        }
    })
    return result
}

const SubscriptionListPage: FC = () => {

    const [busy, setBusy] = useState<boolean>(true)
    const [customers, setCustomers] = useState<CustomerEntry[]>([])
    const [error, setError] = useState<any>()
    const [showAddForm, setShowAddForm] = useState<boolean>(false)
    const {getAccessTokenSilently} = useAuth0()

    const fetch = useCallback(() => {
        setBusy(true)
        fetchSubscriptions(getAccessTokenSilently)
            .then(result => setCustomers(result))
            .catch(err => setError({err}))
            .finally(() => setBusy(false))
    }, [getAccessTokenSilently])

    useEffect(() => fetch(), [fetch])

    const filterValid = useCallback((c: any) => c.status === "valid", [])
    const filterDeleted = useCallback((c: any) => c.status === "deleted", [])

    return (
        <div className='page-document' id="subscriptionListPage">
            {busy ? <Loader/> : null}
            <div className='editor-float'>
                <ColumnsContainer>
                    <HalfColumn>
                        <h1>Subscriptions</h1>
                    </HalfColumn>
                    <HalfColumn className="text-right">
                        <button className="btn btn-primary" onClick={() => setShowAddForm(true)}>
                            Add Contact
                        </button>
                    </HalfColumn>
                </ColumnsContainer>
            </div>
            <div className='grid-lg container page-margin'>
                {showAddForm ? (
                    <AddSubscriptionForm
                        onDone={(success) => {
                            if (success) fetch()
                            setShowAddForm(false)
                        }}
                        excluded={customers.map(c => c.customer.email)}
                    />
                ) : null}
                <FormWrapper>
                    <SubscriptionTable fetch={fetch} customers={customers} filter={filterValid}/>
                </FormWrapper>
                <h3>Archive</h3>
                <FormWrapper>
                    <SubscriptionTable customers={customers} filter={filterDeleted}/>
                </FormWrapper>
                {error ? <div className="toast toast-error mt-2">{error.toString()}</div> : null}
            </div>
        </div>
    )
}

export default SubscriptionListPage
