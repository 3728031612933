import React, {FC, ReactElement, useCallback, useEffect, useState} from "react"
import ky from "ky"
import {Link} from "react-router-dom"
import Loader from "../../_components/Loader/Loader"
import {API_URL} from "../../_helpers/environment"
import {headersNoCache} from "../../_helpers/auth-header"
import {useAuth0} from "@auth0/auth0-react"
import {FormHeader, FormWrapper} from "../../_components/Editor/EditorForm"
import "./Order.scss"
import ErrorHandler from "../../_components/Toast/ErrorHandler"

export const fetchOrders = async (getToken: () => Promise<string>, page: number, isHoreca?: boolean): Promise<any[]> => {
    const t = await getToken()
    const entry = isHoreca ? `${API_URL}/horeca/orders` : `${API_URL}/orders`
    const r = await ky.get(
        `${entry}?skip=${page * 100}&limit=${100}`,
        {"headers": headersNoCache(t)},
    ).json<{ orders: any[] }>()
    return r.orders
}

interface OrderListPageProps {
    isHoreca?: boolean
}

const OrderListPage: FC<OrderListPageProps> = ({isHoreca}) => {

    const {getAccessTokenSilently} = useAuth0()
    const [orders, setOrders] = useState<any[]>([])
    const [busy, setBusy] = useState<boolean>(true)
    const [error, setError] = useState<any>()
    const [page, setPage] = useState<number>(0)
    const [finished, setFinished] = useState<boolean>(false)

    const fetch = useCallback((page: number) => {
        fetchOrders(getAccessTokenSilently, page, isHoreca)
            .then(result => {
                result.sort((a, b) => {
                    return b.orderNumber - a.orderNumber
                })
                setOrders(p => ([...p, ...result]))
                setPage(page + 1)
                if (result.length < 100) {
                    setFinished(true)
                }
            })
            .catch(error => setError(error))
            .finally(() => setBusy(false))
    }, [getAccessTokenSilently, isHoreca])

    useEffect(() => {
        setFinished(false)
        setOrders([])
        fetch(0)
    }, [fetch])

    if (busy) return <Loader/>

    if (error) return <ErrorHandler error={error}/>

    let currentDate = ""
    let today = (new Date()).toISOString().split("T").slice(0, 1).pop()
    const rows: ReactElement[] = []
    for (let i = 0; i < orders.length; i++) {
        const order = orders[i]
        const newDate = order.orderDate?.split("T").slice(0, 1).pop()
        if (newDate !== currentDate) {
            rows.push((
                <tr key={currentDate}>
                    <td style={{"fontWeight": "bold"}} colSpan={6} className="text-center">
                        {(newDate === today) ? "Today" : newDate}
                    </td>
                </tr>
            ))
            currentDate = newDate
        }

        rows.push((
            <tr key={order.orderNumber}>
                <td><Link
                    to={(!isHoreca ? "/transactional/orders/" : "/horeca/orders/") + order.orderNumber}>#{order.orderNumber}</Link>
                </td>
                {!isHoreca ? (<td>&euro;{order.acceptedOffer.price}</td>) : null}
                <td className="hide-sm">{order.orderDate?.split("T")[1].split(":").slice(0, 2).join(":")}</td>
                {!isHoreca ? (
                    <>
                        <td className="hide-lg">{order.customer.givenName}</td>
                        <td className="hide-md">{order.customer.familyName}</td>
                        <td
                            className={
                                (order.partOfInvoice?.paymentStatus?.["@type"] === "PaymentDeclined" ? "text-error" : "") +
                                (order.partOfInvoice?.paymentStatus?.["@type"] === "PaymentComplete" ? "text-success" : "")
                            }
                        >{order.partOfInvoice?.paymentStatus?.["@type"] === "PaymentComplete" ? (
                            order.partOfInvoice.confirmationNumber
                        ) : (
                            order.partOfInvoice?.paymentStatus?.["@type"].replace("Payment", "")
                        )}</td>
                    </>
                ) : (
                    <>
                        <td className="hide-lg">{order.customer.email}</td>
                        <td>{order.orderedItem.length} articles</td>
                    </>
                )}
            </tr>
        ))
    }

    return (
        <div className="grid-xl container">
            <FormHeader>
                <h1>Orders</h1>
            </FormHeader>
            <FormWrapper>
                <table className="orders-table">
                    <tbody>
                    {rows}
                    </tbody>
                </table>
                {(!finished) ? (
                    <div className="text-center mt-2">
                        <button className="btn btn-secondary" onClick={() => fetch(page)}>
                            Show More
                        </button>
                    </div>
                ) : null}
            </FormWrapper>
        </div>
    )
}

export default OrderListPage
