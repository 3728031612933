import React, {FC} from "react"
import EditorPage from "../_components/Editor/EditorPage"
import {TypeCategory, TypeCookingMethod, TypeDish, TypeFoodType} from "../_types/types"
import {
    ColumnsContainer,
    FormWrapper,
    HalfColumn,
    InputContainer,
    InputDivider,
} from "../_components/Editor/EditorForm"
import FormLocalizedInput from "../_components/Input/FormLocalizedInput"
import {EditorSingleDocumentSelect} from "../_components/Editor/EditorInputs"
import {localizedToString} from "../_helpers/localiztion"
import FormInput from "../_components/Input/FormInput"

export const DishEditor: FC = () => (
    <EditorPage<TypeDish>
        name={"Dish"}
        path={"/food-pairing/dishes"}
        endpoint={"/dishes"}
        initial={{
            "@id": "",
            "@type": "",
            "name": [],
            "category": null,
            "identifier": "",
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeCategory>
                            value={val.category}
                            filterBy={"name.@value"}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "category": v}))}
                            endpoint={"/food-types"}
                            name={"Category"}
                        />
                        <InputDivider/>
                        <InputContainer title={"Icon"}>
                            <FormInput
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const FoodTypeEditor: FC = () => (
    <EditorPage<TypeFoodType>
        name={"Food Type"}
        path={"/food-pairing/food-types"}
        endpoint={"/food-types"}
        initial={{
            "@id": "",
            "@type": "",
            "name": [],
            "identifier": "",
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Icon"}>
                            <FormInput
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const CookingMethodEditor: FC = () => (
    <EditorPage<TypeCookingMethod>
        name={"Cooking Method"}
        path={"/food-pairing/cooking-methods"}
        endpoint={"/cooking-methods"}
        initial={{
            "@id": "",
            "@type": "",
            "name": [],
            "identifier": "",
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Icon"}>
                            <FormInput
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)
