import React, {FC, ReactNode, useEffect, useState} from "react"
import ky from "ky"
import {Link, useParams} from "react-router-dom"
import {API_URL} from "../../_helpers/environment"
import Loader from "../../_components/Loader/Loader"
import {headersNoCache} from "../../_helpers/auth-header"
import {useAuth0} from "@auth0/auth0-react"
import {ColumnsContainer, FormFooter, FormHeader, FormWrapper, HalfColumn} from "../../_components/Editor/EditorForm"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons"

const ListItem: FC<{ name: string, value: string | ReactNode }> = ({name, value}) => {
    return (
        <div className="columns document-property">
            <div className="column col-3">
                {name}
            </div>
            <div className="column col-9">
                {value}
            </div>
        </div>
    )
}

const fetchOneOrder = async (id: string, getToken: () => Promise<string>, isHoreca?: boolean): Promise<any> => {
    const t = await getToken()
    const entry = !isHoreca ? `${API_URL}/orders` : `${API_URL}/horeca/orders`
    return await ky.get(`${entry}/${id}`, {"headers": headersNoCache(t)}).json()
}

interface OrderPageProps {
    isHoreca?: boolean
}

const OrderPage: FC<OrderPageProps> = ({isHoreca}) => {

    const {id} = useParams<{ id: string }>()
    const {getAccessTokenSilently} = useAuth0()
    const [order, setOrder] = useState<any>()
    const [busy, setBusy] = useState<boolean>(true)
    const [error, setError] = useState<any>()

    useEffect(() => {
        setError(undefined)
        fetchOneOrder(id || "", getAccessTokenSilently, isHoreca)
            .then(result => setOrder(result))
            .catch(error => setError(error))
            .finally(() => setBusy(false))
    }, [getAccessTokenSilently, id, isHoreca])

    if (busy) return <Loader/>

    if (error) return <div>{error.toString()}</div>

    const deliveryAddress = !isHoreca ? order.orderDelivery : order.customer.address

    return (
        <div className='page-document' id="horecaPage">
            <FormHeader>
                <h1>Order {id}</h1>
            </FormHeader>
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <h5>Customer</h5>
                        {!isHoreca ? (
                            <div className="container">
                                <ListItem name={"Name"}
                                          value={order.customer.givenName + " " + order.customer.familyName}/>
                                <ListItem name={"Email"} value={order.customer.email}/>
                                <ListItem name={"Company (VAT)"}
                                          value={order.customer.affiliation.name + " (" + order.customer.affiliation.vatID + ")"}/>
                                <ListItem name={"Telephone"} value={order.customer.telephone}/>
                            </div>
                        ) : (
                            <div className="container">
                                <ListItem name={"Name"} value={order.customer.name}/>
                                <ListItem name={"Email"} value={order.customer.email}/>
                                <ListItem name={"Telephone"} value={order.customer.telephone}/>
                            </div>
                        )}
                    </FormWrapper>
                    <FormWrapper>
                        <h5>Shipping Address</h5>
                        <div className="container">
                            <ListItem
                                name={"Name"}
                                value={deliveryAddress?.name}
                            />
                            <ListItem
                                name={"Company"}
                                value={deliveryAddress?.alternateName}
                            />
                            <ListItem
                                name={"Street Address"}
                                value={deliveryAddress?.streetAddress}
                            />
                            <ListItem
                                name={"Postal Code"}
                                value={deliveryAddress?.postalCode}
                            />
                            <ListItem
                                name={"Locality"}
                                value={deliveryAddress?.addressLocality}
                            />
                            <ListItem
                                name={"Country"}
                                value={deliveryAddress?.addressCountry}
                            />
                        </div>
                    </FormWrapper>
                    {!isHoreca ? (
                        <FormWrapper>
                            <h5>Billing Address</h5>
                            <div className="container">
                                <ListItem
                                    name={"Street Address"}
                                    value={order.billingAddress?.streetAddress}
                                />
                                <ListItem
                                    name={"Postal Code"}
                                    value={order.billingAddress?.postalCode}
                                />
                                <ListItem
                                    name={"Locality"}
                                    value={order.billingAddress?.addressLocality}
                                />
                                <ListItem
                                    name={"Country"}
                                    value={order.billingAddress?.addressCountry}
                                />
                            </div>
                        </FormWrapper>
                    ) : null}
                </HalfColumn>
                <HalfColumn>
                    <FormWrapper>
                        <h5>Order</h5>
                        <div className="container">
                            {order.acceptedOffer ? (<ListItem
                                name={"Accepted Price"}
                                value={order.acceptedOffer.price}
                            />) : null}
                            <ListItem
                                name={"Items"}
                                value={order.orderedItem.map((item: any) => {
                                    return (
                                        <div key={item.name}>
                                            {item.orderQuantity}x {item.name}
                                        </div>
                                    )
                                })}
                            />
                        </div>
                    </FormWrapper>
                    {!isHoreca ? (
                        <FormWrapper>
                            <h5>Delivery</h5>
                            <div className="container">
                                <ListItem
                                    name={"Method"}
                                    value={order.orderDelivery?.hasDeliveryMethod?.["@type"]}
                                />
                                <ListItem
                                    name={"Cost"}
                                    value={order.orderDelivery?.deliveryCost}
                                />
                            </div>
                        </FormWrapper>
                    ) : null}
                </HalfColumn>
            </ColumnsContainer>
            <FormFooter>
                <Link to="/horeca/orders" className="btn btn-primary">
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                    Return to orders
                </Link>
                {!isHoreca ? (
                    <a className="btn ml-2" href={`${process.env.REACT_APP_DOC_URL}/en/receipts/${order.identifier}`}>
                        Print as receipt
                    </a>
                ) : null}
            </FormFooter>
        </div>
    )
}

export default OrderPage
