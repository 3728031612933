import React, {FC, useCallback, useState} from "react"
import {FormWrapper, InputContainer} from "./EditorForm"
import ky from "ky"
import {EmbeddedImageObject} from "../../_types/types"
import LoaderSmall from "../Loader/LoaderSmall"

interface ImageGeneratorProps {
    title: string
    label: string
    target: string
    onChange: (value: EmbeddedImageObject | null) => void
}

const URL_CGI = process.env.REACT_APP_CGI_URL || ""

export const ImageGenerator: FC<ImageGeneratorProps> = ({target, onChange, ...props}) => {

    const [busy, setBusy] = useState<boolean>(false)

    const callback = useCallback(() => {
        setBusy(true)
        ky.get(`${URL_CGI}${target}`).json<EmbeddedImageObject>()
            .then((res: EmbeddedImageObject) => {
                onChange(res)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setBusy(false)
            })
    }, [setBusy, target, onChange])

    if (busy) {
        return (
            <FormWrapper>
                <LoaderSmall/>
            </FormWrapper>
        )
    }

    return (
        <FormWrapper>
            <InputContainer title={props.title}>
                <div style={{"display": "flex", "height": "100%", "justifyContent": "right"}}>
                    <button
                        onClick={callback}
                        className="btn btn-secondary"
                        style={{"alignSelf": "center"}}
                        type="button"
                    >
                        {props.label}
                    </button>
                </div>
            </InputContainer>
        </FormWrapper>
    )
}
