import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import DashboardPage from "../Dashboard/DashboardPage"
import LocalizationRoutes from "../Localization/LocalizationRoutes"
import ProductRoutes from "../Product/ProductRoutes"
import CustomersRoutes from "../Customers/CustomersRoutes"
import ContentRoutes from "../Content/ContentRoutes"
import TransactionalRoutes from "../Transactional/TransactionalRoutes"
import FoodPairingRoutes from "../FoodPairing/FoodPairingRoutes"
import HorecaRoutes from "../Horeca/HorecaRoutes"

const AppRoutes: FC = () => (
    <Routes>
        <Route path="/" element={<DashboardPage/>}/>
        <Route path="/localization/*" element={<LocalizationRoutes/>}/>
        <Route path="/product/*" element={<ProductRoutes/>}/>
        <Route path="/customers/*" element={<CustomersRoutes/>}/>
        <Route path="/content/*" element={<ContentRoutes/>}/>
        <Route path="/transactional/*" element={<TransactionalRoutes/>}/>
        <Route path="/food-pairing/*" element={<FoodPairingRoutes/>}/>
        <Route path="/horeca/*" element={<HorecaRoutes/>}/>
    </Routes>
)

export default AppRoutes
