import React, {FC} from "react"
import {Auth0Provider} from "@auth0/auth0-react"
import {NavigateFunction, useNavigate} from "react-router-dom"

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || ""
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || ""

interface Props {
    callback: (history: NavigateFunction) => void
    children?: React.ReactNode
}

const Auth0Container: FC<Props> = ({children, callback}) => {
    const navigate = useNavigate()
    return (
        <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            redirectUri={`${window.location.origin}/authenticate`}
            onRedirectCallback={() => callback(navigate)}
            children={children}
        />
    )
}

export default Auth0Container
