import React, {FC} from "react"

const Spinner: FC = () => (
    <>
        <div className="spinner-center"/>
        <div className="spinner-outer"/>
    </>
)

export default Spinner
