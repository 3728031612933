import React, {FunctionComponent} from "react"

interface InputProps {
    id?: string // unique id over page
    placeholder?: string // optional placeholder text, does not work on dates
    value: any
    onChange: (value: any) => void
    className?: string
    required?: boolean
    minlength?: number
    maxlength?: number
    disabled?: boolean
    autoFocus?: boolean
}

interface InputTypedProps extends InputProps {
    type?: string
}

/**
 * Basic form input
 */
const FormInputSimple: FunctionComponent<InputTypedProps> = props => {
    return (
        <input
            required={props.required}
            className={"form-input " + props.className}
            type={props.type || "text"}
            id={props.id}
            placeholder={props.placeholder}
            onChange={e => {
                const v = e.target.value
                if (props.type === "number") {
                    let n = parseInt(v)
                    if (isNaN(n)) {
                        n = 0
                    }
                    props.onChange(n)
                } else {
                    props.onChange(v)
                }
            }}
            value={`${props.value}`}
            minLength={props.minlength}
            maxLength={props.maxlength}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
        />
    )
}

/**
 * Form textarea
 */
const FormInputTextarea: FunctionComponent<InputProps> = props => {
    return (
        <textarea
            required={props.required}
            className={"form-input " + props.className}
            id={props.id}
            placeholder={props.placeholder}
            onChange={e => props.onChange(e.target.value)}
            value={props.value}
            minLength={props.minlength}
            maxLength={props.maxlength}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
        />
    )
}

/**
 * Renders correct input type based on type property
 */
const FormInputRender: FunctionComponent<InputTypedProps> = props => {
    switch (props.type) {
        case "textarea":
            return <FormInputTextarea{...props}/>
        default:
            return <FormInputSimple{...props}/>
    }
}

interface InputGroupProps extends InputTypedProps {
    label?: string
    hint?: string // hint message for input, shows regardless of success and invalid
    success?: boolean // shows hint and input in green
    invalid?: boolean // shows hint and input in red
    labelClassName?: string
}

/**
 * Complete form input group for forms.
 * Includes validation messages, multiple types (including date picker).
 */
const FormInput: FunctionComponent<InputGroupProps> = props => {
    return (
        <div className={`form-group ${props.success ? "has-success" : ""} ${props.invalid ? "has-error" : ""}`}>
            {(props.label && props.id) ? (
                <label className={"form-label " + props.labelClassName}
                       htmlFor={props.id}>{props.label}{props.required ? "*" : ""}</label>
            ) : null}
            <FormInputRender{...props}/>
            {props.hint ? <p className="form-input-hint mb-0">{props.hint}</p> : null}
        </div>
    )
}

export default FormInput
