import React, {FC} from "react"
import "./Loader.scss"
import Spinner from "./Spinner"

const Loader: FC = () => (
    <div className="loader-wrapper">
        <div className="loader-body">
            <Spinner/>
        </div>
    </div>
)

export default Loader
