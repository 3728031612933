export const headers = (authToken: string) => ({
    "Authorization": "Bearer " + authToken,
})
export const headersNoCache = (authToken?: string) => {
    const opts: { [key: string]: string } = {
        "Cache-Control": "no-cache",
    }
    if (authToken) {
        opts["Authorization"] = "Bearer " + authToken
    }
    return opts
}
