import React, {FC, useCallback, useEffect, useState} from "react"
import "./HorecaPage.scss"
import * as base64 from "base-64"
import Loader from "../_components/Loader/Loader"
import {API_URL} from "../_helpers/environment"
import {useAuth0} from "@auth0/auth0-react"
import {FormWrapper} from "../_components/Editor/EditorForm"
import ErrorHandler from "../_components/Toast/ErrorHandler"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons"
import {doDelete, doGetJson, doPost} from "../_services/requests.service"
// @ts-ignore
import {continents, countries} from "country-data-list"

interface HorecaMember {
    email: string
    telephone: string
    country: string
    owner: string
    createdOn: string
    pin: string
}

interface FormProps {
    onDone: (success: boolean) => void
}

const AddSubscriptionForm: FC<FormProps> = ({onDone}) => {

    const {getAccessTokenSilently} = useAuth0()
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [countryCode, setCountryCode] = useState<string>("BE")
    const [busy, setBusy] = useState<boolean>(false)
    const [error, setError] = useState<any>()

    const add = useCallback(() => {
        setBusy(true)
        const payload = {"name": name, "email": email, "telephone": phone, "country": countryCode}
        doPost(`${API_URL}/horeca/members`, {"json": payload}, getAccessTokenSilently)
            .then(() => onDone(true))
            .catch(error => setError(error))
            .finally(() => setBusy(false))
    }, [onDone, getAccessTokenSilently, email, phone, countryCode, name])

    let countryCodes = continents.europe.countries
    let prefixes = countryCodes.map((code: string) => countries[code])
    prefixes = prefixes.filter((c: any) => c.countryCallingCodes.length === 1)
    prefixes = prefixes.filter((c: any) => c.countryCallingCodes[0].indexOf(" ") === -1)
    prefixes = prefixes.sort((a: any, b: any) => {
        const p1 = parseInt(a.countryCallingCodes[0].substring(1))
        const p2 = parseInt(b.countryCallingCodes[0].substring(1))
        return p1 - p2
    })

    return (
        <div className="modal active" id="modal-id">
            {busy ? <Loader/> : null}
            <div className="modal-overlay"/>
            <div className="modal-container">
                <form onSubmit={e => {
                    e.preventDefault()
                    add()
                }}>
                    <div className="modal-header">
                        Add a member
                        <button className="btn btn-clear float-right" type="button" onClick={() => onDone(false)}/>
                    </div>
                    <div className="modal-body">
                        <div className={"form-group"}>
                            <label className="form-label">Name</label>
                            <input
                                value={name}
                                onChange={e => setName(e.target.value)}
                                type="text"
                                className="form-input"
                                placeholder=""
                                autoComplete={"off"}
                            />
                        </div>
                        <div className={"form-group"}>
                            <label className="form-label">Email</label>
                            <input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type="email"
                                className="form-input"
                                placeholder=""
                                autoComplete={"off"}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label className="form-label">Phone</label>
                            <div className="input-group">
                                <select
                                    value={countryCode}
                                    onChange={e => {
                                        const v = e.target.value
                                        setCountryCode(v)
                                    }}
                                    autoComplete={"off"}
                                    className="form-select"
                                    style={{"maxWidth": "80px"}}
                                >
                                    {prefixes.map((country: any) => (
                                        <option key={country.alpha3} value={country.alpha2}>
                                            {country.countryCallingCodes[0]}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    onChange={e => {
                                        const v = e.target.value
                                        setPhone(v)
                                    }}
                                    autoComplete={"off"}
                                    required={true}
                                    value={phone}
                                    inputMode="numeric"
                                    type="text"
                                    className="form-input"
                                />
                            </div>
                        </div>
                        {error ? <div className="toast toast-error mt-2">{error.toString()}</div> : null}
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary input-group-btn"
                            type="submit"
                        >
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const HorecaMemberListPage: FC = () => {

    const [busy, setBusy] = useState<boolean>(true)
    const [showAddForm, setShowAddForm] = useState<boolean>(false)
    const [members, setMembers] = useState<HorecaMember[]>([])
    const [error, setError] = useState<any>()
    const {getAccessTokenSilently} = useAuth0()

    const fetch = useCallback(() => {
        setBusy(true)
        doGetJson<HorecaMember[]>(`${API_URL}/horeca/members`, getAccessTokenSilently)
            .then((result) => {
                result.sort((a, b) => {
                    return (a.email < b.email) ? -1 : 1
                })
                setMembers(result)
            })
            .catch(e => setError(e))
            .finally(() => setBusy(false))
    }, [getAccessTokenSilently])

    const del = useCallback((email: string) => {
        doDelete(`${API_URL}/horeca/members/${base64.encode(email)}`, getAccessTokenSilently)
            .catch(error => setError(error))
            .finally(() => fetch())
    }, [fetch, getAccessTokenSilently])

    const resetPin = useCallback((email: string) => {
        doPost(`${API_URL}/horeca/members/${base64.encode(email)}/reset-pin`, {}, getAccessTokenSilently)
            .then(res => console.log(res))
            .catch(error => setError(error))
            .finally(() => fetch())
    }, [fetch, getAccessTokenSilently])

    useEffect(() => fetch(), [fetch])

    return (
        <div id="horecaPage">
            {busy ? <Loader/> : null}
            <div className='editor-float'>
                <h1>Horeca Members</h1>
            </div>
            {showAddForm ? (
                <AddSubscriptionForm
                    onDone={(success) => {
                        if (success) fetch()
                        setShowAddForm(false)
                    }}
                />
            ) : null}
            <FormWrapper>
                <div className="horeca-table-container">
                    <table className="horeca-members-table">
                        <thead>
                        <tr>
                            <th>UID</th>
                            <th>Email</th>
                            <th>Pin</th>
                            <th>Member Since</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {members.map(m => (
                            <tr key={m.email}>
                                <td>{m.country}{m.telephone}</td>
                                <td>{m.email}</td>
                                <td>{m.pin}</td>
                                <td>{m.createdOn.split("T")[0]}</td>
                                <td className="text-right">
                                    <button className="btn btn-link" onClick={() => resetPin(m.email)}>
                                        Reset Pin
                                    </button>
                                </td>
                                <td className="text-right">
                                    <button className="btn btn-link" onClick={() => del(m.email)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </FormWrapper>
            <div className="editor-footer">
                <button onClick={() => setShowAddForm(true)} className="btn btn-primary mt-2">
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2"/>
                    Add Member
                </button>
            </div>
            {error ? <ErrorHandler error={error}/> : null}
        </div>
    )
}

export default HorecaMemberListPage
