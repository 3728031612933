import React, {FC, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {API_URL} from "../../_helpers/environment"
import {useAuth0} from "@auth0/auth0-react"
import LoaderInline from "../../_components/Loader/LoaderInline"
import {ColumnsContainer, FormWrapper, HalfColumn} from "../../_components/Editor/EditorForm"
import ErrorHandler from "../../_components/Toast/ErrorHandler"
import {doGetJson} from "../../_services/requests.service"

interface NSResponse {
    namespaces: string[]
}

const NamespacesListPage: FC = () => {

    const {getAccessTokenSilently} = useAuth0()
    const [busy, setBusy] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [namespaces, setNamespaces] = useState<string[]>([])

    useEffect(() => {
        doGetJson<NSResponse>(`${API_URL}/locales/en`, getAccessTokenSilently)
            .then((result) => setNamespaces(result.namespaces))
            .catch(e => setError(e))
            .finally(() => setBusy(false))
    }, [getAccessTokenSilently])

    return (
        <>
            <div className='editor-float'>
                <h1 className="mb-0">Translations</h1>
            </div>
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        {busy ? <LoaderInline/> : (
                            <ul className="nav">
                                {namespaces.map(ns => (
                                    <li className="nav-item" key={ns}>
                                        <Link to={`/localization/translations/${ns}`}>{ns}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
            <ErrorHandler error={error}/>
        </>
    )
}

export default NamespacesListPage
