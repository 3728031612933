import React, {FC} from "react"
import "./Loader.scss"

const LoaderSmall: FC = () => (
    <div className="loader-inline-small">
        <div className="loader-body">
            <div className="spinner-center"/>
        </div>
    </div>
)

export default LoaderSmall
