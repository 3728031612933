import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import NamespaceEditorPage from "./Translations/NamespaceEditorPage"
import NamespacesListPage from "./Translations/NamespacesListPage"
import LanguageEditor from "./Languages/LanguageEditor"
import {LanguageListPage} from "../Listing/Listing"

const LocalizationRoutes: FC = () => (
    <Routes>
        <Route path="/translations/:ns" element={<NamespaceEditorPage/>}/>
        <Route path="/translations" element={<NamespacesListPage/>}/>
        <Route path="/languages/:id" element={<LanguageEditor/>}/>
        <Route path="/languages" element={<LanguageListPage/>}/>
    </Routes>
)

export default LocalizationRoutes
