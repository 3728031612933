import React, {FC} from "react"
import EditorPage, {EditorDescription} from "../_components/Editor/EditorPage"
import {TypePaymentMethod, TypeShippingCost} from "../_types/types"
import {
    ColumnsContainer,
    FormWrapper,
    HalfColumn,
    InputContainer,
    InputDivider,
} from "../_components/Editor/EditorForm"
import FormInput from "../_components/Input/FormInput"
import FormCheckbox from "../_components/Input/FormCheckbox"
import {FormCountriesInput} from "../_components/Input/FormCountriesInput"

export const PaymentMethodEditor: FC = () => (
    <EditorPage<TypePaymentMethod>
        name={"Payment Method"}
        path={"/transactional/payment-methods"}
        endpoint={"/payment-methods"}
        initial={{
            "@id": "", "@type": "", "alternateName": "", "identifier": "", "isArchived": false, "name": "",
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"PM Code"}>
                            <FormInput
                                value={val.alternateName}
                                onChange={v => mod(p => ({...p, "alternateName": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Icon"}>
                            <FormInput
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const ShippingCostEditor: FC = () => (
    <EditorPage<TypeShippingCost>
        name={"Shipping Cost"}
        path={"/transactional/shipping-costs"}
        endpoint={"/shipping-costs"}
        initial={{
            "@id": "",
            "@type": "",
            "countries": [],
            "flatFee": 0,
            "inclusiveFrom": 0,
            "name": "",
            "packageExtraLarge": 0,
            "packageLarge": 0,
            "packingFee": 0,
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Packing Fee (x100)"}>
                            <FormInput
                                value={val.packingFee}
                                onChange={v => mod(p => ({...p, "packingFee": v}))}
                                type={"number"}
                            />
                            <EditorDescription>
                                Adds specified amount per bottle for packaging.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Inclusive From (x100)"}>
                            <FormInput
                                value={val.inclusiveFrom}
                                onChange={v => mod(p => ({...p, "inclusiveFrom": v}))}
                                type={"number"}
                            />
                            <EditorDescription>
                                Sets shipping cost to 0 when total order price exceeds this amount.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Flat Fee (x100)"}>
                            <FormInput
                                value={val.flatFee}
                                onChange={v => mod(p => ({...p, "flatFee": v}))}
                                type={"number"}
                            />
                            <EditorDescription>
                                Ignores number of bottles and sets the shipping price to a fixed amount, (except when
                                inclusive).
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Pricing L (1-6) (x100)"}>
                            <FormInput
                                value={val.packageLarge}
                                onChange={v => mod(p => ({...p, "packageLarge": v}))}
                                type={"number"}
                            />
                            <EditorDescription>
                                Price to ship a box of 6 bottles.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Pricing XL (6-18) (x100)"}>
                            <FormInput
                                value={val.packageExtraLarge}
                                onChange={v => mod(p => ({...p, "packageExtraLarge": v}))}
                                type={"number"}
                            />
                            <EditorDescription>
                                Price to ship a box of 18 bottles.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
                <HalfColumn>
                    <FormWrapper>
                        <FormCountriesInput
                            value={val.countries}
                            onChange={v => mod(p => ({...p, "countries": v}))}
                        />
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)
