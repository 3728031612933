import React, {FC, ReactElement, useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons"
import {TypeThing} from "../../_types/types"
import LoaderInline from "../../_components/Loader/LoaderInline"
import ErrorHandler from "../Toast/ErrorHandler"
import FormInput from "../Input/FormInput"
import {ColumnsContainer, FormWrapper, InputDivider} from "./EditorForm"
import {DocumentsOptions, useDocuments} from "../../_services/hooks.service"
import queryString from "query-string"

interface Props<T extends TypeThing> {
    singular: string
    plural: string
    endpoint: string
    header?: ReactElement
    getter: (item: T) => ReactElement
    path: string
    limit?: number
    filterBy?: string
    sortBy?: string
}

const ItemListWrapper: FC<{ children?: React.ReactNode }> = ({children}) => (
    <ul className="nav">
        {children}
    </ul>
)

const TableWrapper: FC<{ children?: React.ReactNode, header?: ReactElement }> = ({children, header}) => (
    <table className="table">
        {header ? <thead>{header}</thead> : null}
        <tbody>
        {children}
        </tbody>
    </table>
)

export const EditorListItemList = <T extends TypeThing>(props: Props<T>): ReactElement => {
    return EditorList({...props, "limit": props.limit || 10}, false)
}

export const EditorListTable = <T extends TypeThing>(props: Props<T>): ReactElement => {
    return EditorList(props, true)
}

const EditorList = <T extends TypeThing>({filterBy, sortBy, ...props}: Props<T>, table: boolean): ReactElement => {

    const [page, setPage] = useState<number>(0)
    const [search, setSearch] = useState<string>("")
    const [options, setOptions] = useState<DocumentsOptions>({
        "delay": 0,
        "sort": [],
        "filter": filterBy ? {
            [filterBy]: search,
        } : {},
        "size": props.limit || 5,
    })

    const locationSearch = window.location.search

    const [hasParsed, setHasParsed] = useState<boolean>(false)
    useEffect(() => {
        if (hasParsed) {
            return
        }
        setHasParsed(true)
        const query = queryString.parse(locationSearch)
        const qPage = query["page"]
        if (typeof qPage === "string") {
            setPage(parseInt(qPage) || 0)
        }
        const qSearch = query["search"]
        if (typeof qSearch === "string") {
            setSearch(qSearch)
        }
    }, [options, hasParsed, locationSearch])

    const navigate = useNavigate()
    const locationPath = window.location.pathname
    useEffect(() => {
        const query = queryString.parse(locationSearch)
        query["page"] = `${page}`
        navigate(`${locationPath}?${queryString.stringify(query)}`, {"replace": true})
    }, [page, navigate, locationPath, locationSearch])
    useEffect(() => {
        const query = queryString.parse(locationSearch)
        query["search"] = search
        navigate(`${locationPath}?${queryString.stringify(query)}`, {"replace": true})
    }, [search, navigate, locationPath, locationSearch])

    useEffect(() => {
        if (filterBy) {
            setOptions(p => ({...p, "filter": {[filterBy]: search}, "page": 0, "delay": 250}))
        }
    }, [filterBy, search])

    useEffect(() => {
        if (sortBy) {
            setOptions(p => ({...p, "sort": [sortBy], "delay": 100}))
        } else if (filterBy) {
            setOptions(p => ({...p, "sort": [filterBy], "delay": 100}))
        }
    }, [sortBy, filterBy])

    useEffect(() => {
        setOptions(p => ({...p, page, "delay": 50}))
    }, [page])

    const {documents, isLoading, error, hasNextPage} = useDocuments<T>(props.endpoint, options)

    const empty = table ? (
        <tr>
            <td style={{"padding": "1.5rem"}} className="text-center">No Results Found</td>
        </tr>
    ) : (
        <li className="nav-item text-center" style={{"padding": "1.5rem"}}>
            No Results Found
        </li>
    )
    const listingInner = documents.length > 0 ? documents.map(item => props.getter(item)) : empty
    const listing = table ? (
        <TableWrapper header={props.header} children={listingInner}/>
    ) : (
        <ItemListWrapper children={listingInner}/>
    )

    return (
        <>
            <div className='editor-float'>
                <h1 className="mb-0">{props.plural}</h1>
            </div>
            {filterBy ? (
                <ColumnsContainer>
                    <div className={"column " + (table ? "col-12" : "col-6 col-lg-12")}>
                        <FormWrapper>
                            <FormInput
                                labelClassName={"pt-0"}
                                value={search}
                                onChange={v => setSearch(v)}
                                placeholder={`Filter by ${filterBy}`}
                                id="pageSearch"
                                label="Search"
                            />
                        </FormWrapper>
                    </div>
                </ColumnsContainer>
            ) : null}
            <ColumnsContainer>
                <div className={"column " + (table ? "col-12" : "col-6 col-lg-12")}>
                    <FormWrapper>
                        {(isLoading) ? <LoaderInline/> : (
                            <>
                                {listing}
                                <InputDivider/>
                                <ul className="pagination">
                                    <li className={"page-item"}>
                                        <button
                                            type="button"
                                            className={"btn btn-link " + (page === 0 ? "disabled" : "")}
                                            onClick={() => setPage(v => v - 1)}
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    <li className="page-item">
                                        <button
                                            type="button"
                                            className={"btn btn-link " + (!hasNextPage ? "disabled" : "")}
                                            onClick={() => setPage(v => v + 1)}
                                        >
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </>
                        )}
                    </FormWrapper>
                </div>
            </ColumnsContainer>
            <ErrorHandler error={error}/>
            <div className="editor-float">
                <Link to={`${props.path}/create`} className="btn btn-primary mt-2">
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2"/>
                    New {props.singular}
                </Link>
            </div>
        </>
    )
}
