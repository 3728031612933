import React, {FC, useEffect} from "react"
import "./App.scss"
import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom"
import Auth0Container from "../Authentication/Auth0Container"
import {onAuthenticatedCallback} from "../_services/auth.service"
import PrivateRoute from "../Authentication/PrivateRoute"
import Loader from "../_components/Loader/Loader"
import AppRoutes from "./AppRoutes"
import Header from "../_components/Header/Header"
import SideMenu from "../_components/SideMenu/SideMenu"
import Footer from "../_components/Footer/Footer"

const AppSignOut: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        onAuthenticatedCallback(navigate)
    }, [navigate])
    return <Loader/>
}

const LayoutContainer: FC<{ children?: React.ReactNode }> = ({children}) => {
    return (
        <>
            <div className="container grid-xl app-container">
                <Header/>
            </div>
            <div className="container grid-xl">
                <div className="columns">
                    <div className="column col-3 col-lg-4 hide-md">
                        <SideMenu/>
                    </div>
                    <div className="column col-9 col-lg-8 col-md-12 editor-container">
                        {children}
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

const App: FC = () => {
    return (
        <Router>
            <Auth0Container callback={onAuthenticatedCallback}>
                <Routes>
                    <Route path={"/authenticate"} element={<Loader/>}/>
                    <Route path={"/sign-out"} element={<AppSignOut/>}/>
                    <Route path={"/*"} element={(
                        <PrivateRoute>
                            <LayoutContainer>
                                <AppRoutes/>
                            </LayoutContainer>
                        </PrivateRoute>
                    )}/>
                </Routes>
            </Auth0Container>
        </Router>
    )
}

export default App
