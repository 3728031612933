export const toIdentifier = (text: string): string => {
    text = text.toLowerCase()
    text = text.replaceAll(/ /g, "-")
    text = text.replaceAll(/[^a-z0-9-]/gi, "")
    while (text.indexOf("--") !== -1) {
        text = text.replaceAll(/--/g, "-")
    }
    while (text[0] === "-") {
        text = text.substr(1)
    }
    while (text[text.length - 1] === "-") {
        text = text.substr(0, text.length - 1)
    }
    return text
}
