import React, {FC} from "react"
import {FormWrapper, InputContainer} from "./EditorForm"
import {
    FormDocumentInput,
    FormSingleDocumentInput,
    PropsSelectMulti,
    PropsSelectSingle,
} from "../Input/FormDocumentInput"
import {EmbeddedImageObject, TypeThing} from "../../_types/types"
import FormImageInput from "../Input/FormImageInput"
import {EditorImageSingle} from "./EditorImages"

type SingleDocSelectProps<T> = PropsSelectSingle<T> & { name: string, endpoint: string }

export const EditorSingleDocumentSelect = <T extends TypeThing>({value, name, ...props}: SingleDocSelectProps<T>) => (
    <InputContainer title={name} lead={`${value ? "1" : "0"}/1 selected`}>
        <FormSingleDocumentInput<T>
            value={value}
            {...props}
        />
    </InputContainer>
)

type MultiDocSelectProps<T> = PropsSelectMulti<T> & { name: string, endpoint: string }

export const EditorMultiDocumentSelect = <T extends TypeThing>({
                                                                   value,
                                                                   name,
                                                                   limit,
                                                                   ...props
                                                               }: MultiDocSelectProps<T>) => (
    <InputContainer title={name} lead={`${value.length}${limit ? `/${limit}` : ""} selected`}>
        <FormDocumentInput<T>
            value={value}
            limit={limit}
            {...props}
        />
    </InputContainer>
)

interface SingleImageContainerProps {
    title: string
    fullSize?: string
    thumbSize?: string
    value: EmbeddedImageObject | null
    onChange: (value: EmbeddedImageObject | null) => void
    showCaption?: boolean
}

export const SingleImageContainer: FC<SingleImageContainerProps> = ({title, showCaption, ...props}) => (
    <>
        <FormWrapper>
            <InputContainer title={title} lead={props.fullSize}>
                <FormImageInput{...props}/>
            </InputContainer>
        </FormWrapper>
        <EditorImageSingle
            showCaption={showCaption || false}
            {...props}
        />
    </>
)
