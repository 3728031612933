import React, {FC} from "react"
import EditorPage, {EditorDescription} from "../_components/Editor/EditorPage"
import {
    TypeAppellation,
    TypeCategory,
    TypeCountry,
    TypeDiscoveryCase,
    TypeGrape,
    TypeManufacturer,
    TypePropertyValue,
    TypeWine,
} from "../_types/types"
import FormInput from "../_components/Input/FormInput"
import FormLocalizedInput from "../_components/Input/FormLocalizedInput"
import FormCheckbox from "../_components/Input/FormCheckbox"
import {
    ColumnsContainer,
    FormWrapper,
    HalfColumn,
    InputContainer,
    InputDivider,
} from "../_components/Editor/EditorForm"
import {localizedToString} from "../_helpers/localiztion"
import {
    EditorMultiDocumentSelect,
    EditorSingleDocumentSelect,
    SingleImageContainer,
} from "../_components/Editor/EditorInputs"
import {FormSelectString} from "../_components/Input/FormSelect"
import FormImageInput from "../_components/Input/FormImageInput"
import {EditorImageSingle} from "../_components/Editor/EditorImages"
import {toIdentifier} from "../_helpers/identifiers"
import {ImageGenerator} from "../_components/Editor/EditorAutoGenerator"

const stockOptions = {
    "": "- Specify -",
    "in-stock": "In Stock",
    "out-of-stock": "Out of Stock",
    "call-for-availability": "Call for Availability",
    "discontinued": "Discontinued",
    "sold-out": "Sold Out",
}

export const GrapeEditor: FC = () => (
    <EditorPage<TypeGrape>
        name={"Grape"}
        path={"/product/grapes"}
        endpoint={"/grapes"}
        initial={{
            "@id": "",
            "@type": "",
            "name": "",
            "isArchived": false,
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                type={"text"}
                                id={"inputName"}
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                id={"inputCheckbox"}
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

interface PropertyValueProps {
    title: string
    name: string
    path: string
    endpoint: string
}

const PropertyValueEditor: FC<PropertyValueProps> = ({title, name, path, endpoint}) => (
    <EditorPage<TypePropertyValue>
        name={title}
        path={path}
        endpoint={endpoint}
        initial={{
            "@id": "",
            "@type": "",
            "name": name,
            "isArchived": false,
            "identifier": "",
            "value": [],
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                id={"inputValue"}
                                value={val.value}
                                onChange={v => mod(p => ({...p, "value": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                id={"inputCheckbox"}
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const AgricultureEditor: FC = () => (
    <PropertyValueEditor
        title={"Agriculture"}
        name={"Agriculture"}
        path={"/product/agriculture"}
        endpoint={"/agriculture"}
    />
)

export const BottleCapEditor: FC = () => (
    <PropertyValueEditor
        title={"Bottle Closure"}
        name={"Bottle Cap"}
        path={"/product/bottle-caps"}
        endpoint={"/bottle-caps"}
    />
)

export const AgingEditor: FC = () => (
    <PropertyValueEditor
        title={"Ageing"}
        name={"Aging"}
        path={"/product/agings"}
        endpoint={"/agings"}
    />
)

export const CategoryEditor: FC = () => (
    <EditorPage<TypeCategory>
        name={"Category"}
        path={"/product/categories"}
        endpoint={"/categories"}
        initial={{
            "@id": "",
            "@type": "",
            "name": [],
            "parent": null,
            "identifier": "",
            "order": 0,
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                id={"inputName"}
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeCategory>
                            value={val.parent}
                            filterBy={"name.@value"}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "parent": v}))}
                            endpoint={"/categories"}
                            name={"Parent Category"}
                        />
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Rank"}>
                            <FormInput
                                type={"number"}
                                id={"inputOrder"}
                                value={val.order}
                                onChange={v => mod(p => ({...p, "order": v}))}
                            />
                            <EditorDescription>
                                Listings are sorted using this number from low to high.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const CountryEditor: FC = () => (
    <EditorPage<TypeCountry>
        name={"Country"}
        path={"/product/countries"}
        endpoint={"/countries"}
        initial={{
            "@id": "",
            "@type": "",
            "name": [],
            "identifier": "",
            "alternateName": "",
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                id={"inputName"}
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Code"}>
                            <FormInput
                                type={"text"}
                                id={"inputAlternateName"}
                                value={val.alternateName}
                                onChange={v => mod(p => ({...p, "alternateName": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const AppellationEditor: FC = () => (
    <EditorPage<TypeAppellation>
        name={"Appellation"}
        path={"/product/appellations"}
        endpoint={"/appellations"}
        initial={{
            "@id": "",
            "@type": "",
            "isArchived": false,
            "name": "",
            "identifier": "",
            "addressCountry": null,
            "latitude": 0,
            "longitude": 0,
            "url": "",
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                id={"inputName"}
                                value={val.name}
                                type={"text"}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Place ID"}>
                            <FormInput
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeCountry>
                            value={val.addressCountry}
                            filterBy={"name.@value"}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "addressCountry": v}))}
                            endpoint={"/countries"}
                            name={"Country"}
                        />
                        <InputDivider/>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                id={"inputCheckbox"}
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const ManufacturerEditor: FC = () => (
    <EditorPage<TypeManufacturer>
        name={"Vigneron"}
        path={"/product/manufacturers"}
        endpoint={"/manufacturers"}
        initial={{
            "@id": "",
            "@type": "",
            "isArchived": false,
            "identifier": "",
            "name": "",
            "url": "",
            "video": "",
            "description": [],
            "image": null,
            "layout": "",
            "founder": {
                "name": "",
                "image": null,
            },
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                id={"inputName"}
                                value={val.name}
                                type={"text"}
                                onChange={v => mod(p => ({...p, "name": v, "identifier": toIdentifier(v)}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                disabled={true}
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={() => null}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Layout"}>
                            <FormSelectString
                                id={"inputLayout"}
                                value={val.layout}
                                onChange={v => mod(p => ({...p, "layout": v}))}
                                options={{
                                    "": "Default",
                                    "vertical": "Vertical",
                                }}
                            />
                            <EditorDescription>
                                Layout used to render Wine Story.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Description"}>
                            <FormLocalizedInput
                                textarea={true}
                                id={"inputDescription"}
                                value={val.description}
                                onChange={v => mod(p => ({...p, "description": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Video URL"}>
                            <FormInput
                                id={"inputVideoURL"}
                                type={"text"}
                                value={val.video}
                                onChange={v => mod(p => ({...p, "video": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"URL"}>
                            <FormInput
                                id={"inputURL"}
                                type={"text"}
                                value={val.url}
                                onChange={v => mod(p => ({...p, "url": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                id={"inputCheckbox"}
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
                <HalfColumn>
                    <SingleImageContainer
                        title={"Banner"}
                        value={val.image}
                        onChange={v => mod(p => ({...p, "image": v}))}
                        fullSize={"1200x1200"}
                        thumbSize={"600x600"}
                    />
                    <FormWrapper>
                        <InputContainer title={"Founder"}>
                            <FormInput
                                type={"text"}
                                id={"inputFounderName"}
                                value={val.founder.name}
                                onChange={v => mod(p => ({...p, "founder": {...p.founder, "name": v}}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Signature"}>
                            <FormImageInput
                                onChange={v => mod(p => ({...p, "founder": {...p.founder, "image": v}}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                    <EditorImageSingle
                        showCaption={false}
                        value={val.founder.image}
                        onChange={v => mod(p => ({...p, "founder": {...p.founder, "image": v}}))}
                    />
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const WineEditor: FC = () => (
    <EditorPage<TypeWine>
        name={"Wine"}
        path={"/product/wines"}
        endpoint={"/wines"}
        initial={{
            "@id": "",
            "@type": "Wine",
            "aging": null,
            "agingPotential": 0,
            "agriculture": null,
            "alcoholByVolume": "",
            "appellation": null,
            "isArchived": false,
            "bottleCap": null,
            "category": null,
            "description": [],
            "gtin": "",
            "identifier": "",
            "image": null,
            "manufacturer": null,
            "material": [],
            "mpn": "",
            "name": "",
            "offers": {
                "@type": "Offer",
                "integerPrice": 0,
                "priceCurrency": "EUR",
                "availability": "",
                "availabilityStarts": "",
            },
            "sku": "",
            "vintage": 0,
            "volume": 0,
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <SingleImageContainer
                        title={"Picture"}
                        value={val.image}
                        onChange={v => mod(p => ({...p, "image": v}))}
                        fullSize={"750x1000"}
                        thumbSize={"225x300"}
                    />
                    <FormWrapper>
                        <EditorSingleDocumentSelect<TypeManufacturer>
                            value={val.manufacturer}
                            filterBy={"name"}
                            getName={d => d.name}
                            onChange={v => mod(p => ({...p, "manufacturer": v}))}
                            endpoint={"/manufacturers"}
                            name={"Vignergon"}
                        />
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeAppellation>
                            value={val.appellation}
                            filterBy={"name"}
                            getName={d => d.name}
                            onChange={v => mod(p => ({...p, "appellation": v}))}
                            endpoint={"/appellations"}
                            name={"Appellation"}
                        />
                        <InputDivider/>
                        <InputContainer title={"Vintage"}>
                            <FormInput
                                type={"number"}
                                id={"inputVintage"}
                                value={val.vintage}
                                onChange={v => mod(p => ({...p, "vintage": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypePropertyValue>
                            value={val.bottleCap}
                            filterBy={"value.@value"}
                            getName={d => localizedToString(d.value)}
                            onChange={v => mod(p => ({...p, "bottleCap": v}))}
                            endpoint={"/bottle-caps"}
                            name={"Bottle Closure"}
                        />
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypePropertyValue>
                            value={val.agriculture}
                            filterBy={"value.@value"}
                            getName={d => localizedToString(d.value)}
                            onChange={v => mod(p => ({...p, "agriculture": v}))}
                            endpoint={"/agriculture"}
                            name={"Viticulture"}
                        />
                        <InputDivider/>
                        <InputContainer title={"Alcohol"}>
                            <FormInput
                                type={"string"}
                                id={"inputAlcohol"}
                                value={val.alcoholByVolume}
                                onChange={v => mod(p => ({...p, "alcoholByVolume": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Volume (ml)"}>
                            <FormInput
                                type={"number"}
                                id={"inputVolume"}
                                value={val.volume}
                                onChange={v => mod(p => ({...p, "volume": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Aging Potential"}>
                            <FormInput
                                type={"number"}
                                id={"inputAgingPotential"}
                                value={val.agingPotential}
                                onChange={v => mod(p => ({...p, "agingPotential": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypePropertyValue>
                            value={val.aging}
                            filterBy={"value.@value"}
                            getName={d => localizedToString(d.value)}
                            onChange={v => mod(p => ({...p, "aging": v}))}
                            endpoint={"/agings"}
                            name={"Ageing"}
                        />
                    </FormWrapper>
                </HalfColumn>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                id={"inputName"}
                                value={val.name}
                                type={"text"}
                                onChange={v => mod(p => ({...p, "name": v, "identifier": toIdentifier(v)}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                id={"inputIdentifier"}
                                className="disabled"
                                value={val.identifier}
                                onChange={() => null}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Description"}>
                            <FormLocalizedInput
                                id={"inputDescription"}
                                textarea={true}
                                value={val.description}
                                onChange={v => mod(p => ({...p, "description": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeCategory>
                            value={val.category}
                            filterBy={"name.@value"}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "category": v}))}
                            endpoint={"/categories"}
                            name={"Category"}
                        />
                        <InputDivider/>
                        <EditorMultiDocumentSelect<TypeGrape>
                            value={val.material}
                            filterBy={"name"}
                            getName={d => d.name}
                            onChange={v => mod(p => ({...p, "material": v}))}
                            endpoint={"/grapes"}
                            name={"Grapes"}
                        />
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"GTIN"}>
                            <FormInput
                                id={"inputGTIN"}
                                type={"text"}
                                value={val.gtin}
                                onChange={v => mod(p => ({...p, "gtin": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"MPN"}>
                            <FormInput
                                id={"inputMPN"}
                                type={"text"}
                                value={val.mpn}
                                onChange={v => mod(p => ({...p, "mpn": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"SKU"}>
                            <FormInput
                                id={"inputSKU"}
                                type={"text"}
                                value={val.sku}
                                onChange={v => mod(p => ({...p, "sku": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Price (x100)"}>
                            <FormInput
                                id={"inputPrice"}
                                value={val.offers.integerPrice}
                                type={"number"}
                                onChange={v => mod(p => ({...p, "offers": {...p.offers, "integerPrice": v}}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Availability"}>
                            <FormSelectString
                                id={"inputAvailability"}
                                value={val.offers.availability}
                                onChange={v => mod(p => ({...p, "offers": {...p.offers, "availability": v}}))}
                                options={stockOptions}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Available From"}>
                            <FormInput
                                id={"inputAvailabilityStarts"}
                                value={val.offers.availabilityStarts}
                                type={"text"}
                                onChange={v => mod(p => ({...p, "offers": {...p.offers, "availabilityStarts": v}}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                id={"inputCheckbox"}
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const DiscoveryCaseEditor: FC = () => (
    <EditorPage<TypeDiscoveryCase>
        name={"Discovery Case"}
        path={"/product/discovery-cases"}
        endpoint={"/discovery-cases"}
        initial={{
            "@id": "",
            "@type": "Wine",
            "isArchived": false,
            "category": null,
            "description": [],
            "image": null,
            "lead": [],
            "name": [],
            "offers": {
                "@type": "Offer",
                "integerPrice": 0,
                "priceCurrency": "EUR",
                "availability": "",
                "availabilityStarts": "",
            },
            "wines": [],
            "weight": {
                "unitText": "XBO",
                "value": "0",
            },
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <SingleImageContainer
                        title={"Picture"}
                        value={val.image}
                        onChange={v => mod(p => ({...p, "image": v}))}
                        fullSize={"1200x800"}
                        thumbSize={"500x400"}
                    />
                    {val["@id"] ? (
                        <ImageGenerator
                            label={"Auto-generate product photo"}
                            title={"Image Generator"}
                            onChange={v => mod(p => ({...p, "image": v}))}
                            target={`/discovery-cases/${val["@id"].split("/").pop()}`}
                        />
                    ) : (
                        <FormWrapper>
                            <InputContainer title={"Image Generator"}>
                                Cannot generate image until discovery case is saved.
                            </InputContainer>
                        </FormWrapper>
                    )}
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                id={"inputName"}
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Description"}>
                            <FormLocalizedInput
                                id={"inputDescription"}
                                textarea={true}
                                value={val.description}
                                onChange={v => mod(p => ({...p, "description": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Lead"}>
                            <FormLocalizedInput
                                id={"inputLead"}
                                textarea={true}
                                value={val.lead}
                                onChange={v => mod(p => ({...p, "lead": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeCategory>
                            value={val.category}
                            filterBy={"name.@value"}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "category": v}))}
                            endpoint={"/categories"}
                            name={"Category"}
                        />
                        <EditorDescription>
                            Any category can be used. Only categories that have one or more cases will be visible.
                        </EditorDescription>
                    </FormWrapper>
                </HalfColumn>
                <HalfColumn>
                    <FormWrapper>
                        <EditorMultiDocumentSelect<TypeWine>
                            value={val.wines}
                            filterBy={"name"}
                            getName={d => d.name}
                            onChange={v => {
                                mod(p => ({...p, "wines": v, "weight": {...p.weight, "value": `${v.length}`}}))
                            }}
                            endpoint={"/wines"}
                            name={"Wines"}
                        />
                        <InputDivider/>
                        <InputContainer title={"Bottle Count"}>
                            <FormInput
                                type={"number"}
                                id={"inputBottleCount"}
                                value={parseInt(val.weight.value)}
                                onChange={v => mod(p => ({...p, "weight": {...p.weight, "value": `${v}`}}))}
                            />
                            <EditorDescription>
                                Set a custom bottle value to set shipping cost to a fixed value. When 0 shipping will
                                be based on number of wines selected.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Price (x100)"}>
                            <FormInput
                                id={"inputPrice"}
                                value={val.offers.integerPrice}
                                type={"number"}
                                onChange={v => mod(p => ({...p, "offers": {...p.offers, "integerPrice": v}}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Availability"}>
                            <FormSelectString
                                id={"inputAvailability"}
                                value={val.offers.availability}
                                onChange={v => mod(p => ({...p, "offers": {...p.offers, "availability": v}}))}
                                options={stockOptions}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Available From"}>
                            <FormInput
                                id={"inputAvailabilityStarts"}
                                value={val.offers.availabilityStarts}
                                type={"text"}
                                onChange={v => mod(p => ({...p, "offers": {...p.offers, "availabilityStarts": v}}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                id={"inputCheckbox"}
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)
