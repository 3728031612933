import React, {FC, useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import Loader from "../_components/Loader/Loader"
import {fetchOrders} from "../Transactional/Order/OrderListPage"
import ErrorHandler from "../_components/Toast/ErrorHandler"
import {FormHeader, FormWrapper} from "../_components/Editor/EditorForm"
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleRight} from "@fortawesome/free-solid-svg-icons"

const OrdersModule: FC = () => {
    const {getAccessTokenSilently} = useAuth0()
    const [orders, setOrders] = useState<any[]>([])
    const [busy, setBusy] = useState<boolean>(true)
    const [error, setError] = useState<any>()
    useEffect(() => {
        fetchOrders(getAccessTokenSilently, 0)
            .then(result => {
                result.sort((a, b) => {
                    return b.orderNumber - a.orderNumber
                })
                setOrders(result)
            })
            .catch(error => setError(error))
            .finally(() => setBusy(false))
    }, [getAccessTokenSilently])

    const dataMap: { [key: string]: any } = {}
    for (let i = 0; i < (7 * 4); i++) {
        let d = new Date()
        d.setDate(d.getDate() - i)
        let s = d.toISOString().split("T")[0]
        dataMap[s] = {
            "name": s,
            "revenue": 0.0,
        }
    }

    let totalRevenue = 0
    let totalOrders = 0
    orders.forEach(o => {
        const date = o.orderDate.split("T")[0]
        if (!dataMap[date]) {
            return
        }
        if (o.orderStatus?.["@type"] === "OrderProcessing" && o.partOfInvoice?.paymentStatus?.["@type"] !== "PaymentDeclined") {
            dataMap[date].revenue += parseFloat(o.acceptedOffer.price)
            totalRevenue += parseFloat(o.acceptedOffer.price)
            totalOrders += 1
        }
    }, [])

    if (busy) return <Loader/>
    if (error) return <ErrorHandler error={error}/>

    return (
        <>
            <FormWrapper>
                <h3>Orders</h3>
                <div>Past 4 weeks</div>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={Object.values(dataMap).reverse()}
                        margin={{
                            "top": 5, "right": 30,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="revenue" fill="#037575"/>
                    </BarChart>
                </ResponsiveContainer>
                <div>Total revenue: &euro;{totalRevenue} | Number of orders: {totalOrders}</div>
            </FormWrapper>
            <Link
                to="/transactional/orders"
                className="btn btn-primary"
            >Go to orders <FontAwesomeIcon icon={faAngleRight} className="ml-2"/></Link>
        </>
    )

}

const DashboardPage: FC = () => {

    return (
        <FormHeader>
            <h1>Dashboard</h1>
            <OrdersModule/>
        </FormHeader>
    )
}

export default DashboardPage
