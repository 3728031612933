import React, {FC, useEffect} from "react"
import {EmbeddedImageObject} from "../../_types/types"
import {FormWrapper, InputContainer, InputDivider} from "./EditorForm"
import "./EditorImages.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import FormLocalizedInput from "../Input/FormLocalizedInput"
import {localizedToString} from "../../_helpers/localiztion"
import LoaderSmall from "../Loader/LoaderSmall"

interface SingleProps {
    value: EmbeddedImageObject | null
    onChange: (v: EmbeddedImageObject | null) => void
    showCaption: boolean
}

interface ItemProps {
    value: EmbeddedImageObject
    onChange: (v: EmbeddedImageObject | null) => void
    showCaption: boolean
}

const EmbeddedImageItem: FC<ItemProps> = ({value, onChange, showCaption}) => {

    useEffect(() => {
        if (value.name === null) {
            onChange({...value, "name": []})
        }
        if (value.caption === null && showCaption) {
            onChange({...value, "caption": []})
        }
    }, [showCaption, onChange, value])

    if (value.name === null || (value.caption === null && showCaption)) {
        return <LoaderSmall/>
    }

    return (
        <FormWrapper>
            <InputContainer title={"Thumbnail"}>
                <div className="form-image">
                    <img src={value.thumbnail.contentUrl} alt={localizedToString(value.name)}/>
                </div>
            </InputContainer>
            <InputDivider/>
            <InputContainer title={"Name"}>
                <FormLocalizedInput
                    id={value.contentUrl + "/name"}
                    value={value.name}
                    onChange={v => onChange({...value, "name": v})}
                />
            </InputContainer>
            {(showCaption && value.caption !== null) ? (
                <>
                    <InputDivider/>
                    <InputContainer title={"Caption"}>
                        <FormLocalizedInput
                            id={value.contentUrl + "/name"}
                            value={value.caption}
                            onChange={v => onChange({...value, "caption": v})}
                        />
                    </InputContainer>
                </>
            ) : null}
            <InputDivider/>
            <div className="text-right">
                <button onClick={() => onChange(null)} className="btn mt-2" type="button">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2"/>
                    Delete Image
                </button>
            </div>
        </FormWrapper>
    )
}

export const EditorImageSingle: FC<SingleProps> = ({value, onChange, showCaption}) => {
    if (value === null || value === undefined) {
        return null
    }
    return (
        <EmbeddedImageItem value={value} onChange={onChange} showCaption={showCaption}/>
    )
}
