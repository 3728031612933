import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import {PaymentMethodsListPage, ShippingCostsListPage} from "../Listing/Listing"
import {PaymentMethodEditor, ShippingCostEditor} from "./TransactionalEditors"
import OrderListPage from "./Order/OrderListPage"
import OrderPage from "./Order/OrderPage"

const TransactionalRoutes: FC = () => (
    <Routes>
        <Route path="/payment-methods" element={<PaymentMethodsListPage/>}/>
        <Route path="/payment-methods/:id" element={<PaymentMethodEditor/>}/>
        <Route path="/shipping-costs" element={<ShippingCostsListPage/>}/>
        <Route path="/shipping-costs/:id" element={<ShippingCostEditor/>}/>
        <Route path="/orders" element={<OrderListPage/>}/>
        <Route path="/orders/:id" element={<OrderPage/>}/>
    </Routes>
)

export default TransactionalRoutes
