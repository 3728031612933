import React, {ReactElement, useCallback, useEffect, useState} from "react"
import {TypeReference, TypeThing} from "../../_types/types"
import {DocumentsOptions, useDocument, useDocuments} from "../../_services/hooks.service"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons"
import "./FormDocumentInput.scss"
import ErrorHandler from "../Toast/ErrorHandler"
import FormInput from "./FormInput"
import LoaderSmall from "../Loader/LoaderSmall"

interface SelectedProps<T> {
    id: string
    getName: (d: T) => string
    onClick: (id: string) => void
}

const SelectedDocument = <T extends TypeThing>({id, onClick, getName}: SelectedProps<T>) => {

    const {document, isLoading, error} = useDocument<T>(id)

    let name = id.split("/").pop()
    if (error) {
        name = "document error: " + name
    } else if (isLoading) {
        name = "Fetching..."
    } else if (document !== null) {
        name = getName(document)
    }

    return (
        <tr>
            <td>
                {name}
            </td>
            <td>
                <button className="btn btn-circle btn-red" type="button" onClick={() => onClick(id)}>
                    <FontAwesomeIcon icon={faMinus}/>
                </button>
            </td>
        </tr>
    )
}

export interface PropsSelectSingle<T> {
    value: TypeReference | null
    getName: (d: T) => string
    onChange: (v: TypeReference | null) => void
    endpoint: string
    filterBy: string
}

export const FormSingleDocumentInput = <T extends TypeThing>({
                                                                 onChange,
                                                                 value,
                                                                 ...props
                                                             }: PropsSelectSingle<T>): ReactElement => (
    <FormDocumentInput
        {...props}
        value={(value !== null) ? [value] : []}
        onChange={v => onChange((v.length === 0) ? null : v[0])}
        limit={1}
    />
)

export interface PropsSelectMulti<T> {
    value: TypeReference[]
    getName: (d: T) => string
    onChange: (v: TypeReference[]) => void
    endpoint: string
    limit?: number
    filterBy: string
}

export const FormDocumentInput = <T extends TypeThing>({
                                                           value,
                                                           filterBy,
                                                           onChange,
                                                           endpoint,
                                                           getName,
                                                           limit,
                                                       }: PropsSelectMulti<T>): ReactElement => {

    const [search, setSearch] = useState<string>("")
    const [options, setOptions] = useState<DocumentsOptions>({
        "onlySearchOnQuery": true,
        "delay": 500,
        "filter": {
            [filterBy]: search,
        },
    })
    const {documents, isLoading, error} = useDocuments<T>(endpoint, options)

    useEffect(() => {
        setOptions(p => ({...p, "filter": {[filterBy]: search}}))
    }, [filterBy, search])

    const onRemove = useCallback((id: string) => {
        onChange(value.filter(s => s["@id"] !== id))
    }, [onChange, value])

    const selected = value.map(v => v["@id"])

    return (
        <div>
            {selected.length > 0 ? (
                <>
                    <table className="form-input-document">
                        <tbody>
                        {selected.map(s => (
                            <SelectedDocument
                                key={s}
                                getName={getName}
                                id={s}
                                onClick={onRemove}
                            />
                        ))}
                        </tbody>
                    </table>
                    <div className="divider"/>
                </>
            ) : null}
            <table className="form-input-document">
                <tbody>
                <tr>
                    <td colSpan={2}>
                        <FormInput
                            placeholder={`Type to search by ${filterBy}`}
                            value={search}
                            onChange={v => setSearch(v)}
                        />
                    </td>
                </tr>
                {isLoading ? <tr>
                    <td colSpan={2}><LoaderSmall/></td>
                </tr> : null}
                {!isLoading && documents.length === 0 ? (
                    <tr>
                        {search !== "" ? <td className="py-2 text-small">No documents found</td> : null}
                        <td/>
                    </tr>
                ) : null}
                {!isLoading ? documents.map(d => {
                    let included = selected.includes(d["@id"])
                    return (
                        <tr key={d["@id"]}>
                            <td>
                                {getName(d)}
                            </td>
                            <td>
                                {included ? (
                                    <button
                                        className="btn btn-circle btn-red"
                                        type="button"
                                        onClick={() => onRemove(d["@id"])}
                                    >
                                        <FontAwesomeIcon icon={faMinus}/>
                                    </button>
                                ) : (
                                    <button className="btn btn-circle btn-green" type="button" onClick={() => {
                                        let ls = [...value]
                                        while (limit !== undefined && (ls.length + 1) > limit) {
                                            ls.splice(0, 1)
                                        }
                                        onChange([...ls, {"@id": d["@id"]}])
                                    }}>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </button>
                                )}
                            </td>
                        </tr>
                    )
                }) : null}
                </tbody>
            </table>
            <ErrorHandler error={error}/>
        </div>
    )
}
