import React, {FC} from "react"
import {TypeLanguage} from "../../_types/types"
import FormInput from "../../_components/Input/FormInput"
import EditorPage from "../../_components/Editor/EditorPage"
import {InputContainer, InputDivider} from "../../_components/Editor/EditorForm"
import FormCheckbox from "../../_components/Input/FormCheckbox"

export const LanguageEditor: FC = () => (
    <EditorPage<TypeLanguage>
        name={"Language"}
        path={"/localization/languages"}
        endpoint={"/languages"}
        initial={{
            "@id": "",
            "@type": "",
            "name": "",
            "alternateName": "",
            "isArchived": false,
        }}
        form={({mod, val}) => (
            <div className="columns">
                <div className="column col-6 col-lg-12">
                    <div className='editor-page'>
                        <div className="columns">
                            <div className="column col-3">
                                <label className="form-label" htmlFor="input-example-1">Name</label>
                            </div>
                            <div className="column col-9">
                                <FormInput
                                    type={"text"}
                                    id={"inputName"}
                                    value={val.name}
                                    onChange={v => mod(p => ({...p, "name": v}))}
                                />
                            </div>
                        </div>
                        <div className="page-divider"/>
                        <div className="columns">
                            <div className="column col-3">
                                <label className="form-label" htmlFor="input-example-1">Code</label>
                            </div>
                            <div className="column col-9">
                                <FormInput
                                    type={"text"}
                                    id={"inputCode"}
                                    value={val.alternateName}
                                    onChange={v => mod(p => ({...p, "alternateName": v}))}
                                />
                            </div>
                        </div>
                        <InputDivider/>
                        <InputContainer title={"Archival"}>
                            <FormCheckbox
                                id={"inputCheckbox"}
                                label={"Mark as archived"}
                                value={val.isArchived}
                                onChange={v => mod(p => ({...p, "isArchived": v}))}
                            />
                        </InputContainer>
                    </div>
                </div>
            </div>
        )}
    />
)

export default LanguageEditor
