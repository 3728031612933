import React, {FC, useEffect, useState} from "react"

const formatMessage = (text?: string): string => {
    if (text === "" || text === undefined) {
        return ""
    }
    text = text[0].toUpperCase() + text.substr(1)
    text = text.replace(": Invalid type.", " is of an incorrect type.\n")
    text = text.replace("Expected: ", "Expected an ")
    text = text.replace("given: null", "is empty instead")
    text = text.replace("given:", "given")
    return text
}

const ErrorHandler: FC<{ error: any }> = ({error}) => {

    const [message, setMessage] = useState<string>()

    useEffect(() => {
        if (error?.response) {
            if (!error.response.bodyUsed) {
                error.response.text()
                    .then((msg: string) => setMessage(msg))
            }
        } else {
            setMessage(undefined)
        }
    }, [error])

    if (!error) {
        return null
    }

    return (
        <div className="toast toast-error mt-2">
            <div><b>{error.toString()}</b></div>
            <div>
                {formatMessage(message)}
            </div>
        </div>
    )
}

export default ErrorHandler
