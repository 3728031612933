import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import {CookingMethodsListPage, DishesListPage, FoodMatchListPage, FoodTypesListPage} from "../Listing/Listing"
import {CookingMethodEditor, DishEditor, FoodTypeEditor} from "./FoodPairingEditors"
import FoodMatchEditor from "./FoodMatches/FoodMatchEditor"

const FoodPairingRoutes: FC = () => (
    <Routes>
        <Route path="/dishes" element={<DishesListPage/>}/>
        <Route path="/dishes/:id" element={<DishEditor/>}/>
        <Route path="/food-types" element={<FoodTypesListPage/>}/>
        <Route path="/food-types/:id" element={<FoodTypeEditor/>}/>
        <Route path="/cooking-methods" element={<CookingMethodsListPage/>}/>
        <Route path="/cooking-methods/:id" element={<CookingMethodEditor/>}/>
        <Route path="/food-matches" element={<FoodMatchListPage/>}/>
        <Route path="/food-matches/:id" element={<FoodMatchEditor/>}/>
    </Routes>
)

export default FoodPairingRoutes
