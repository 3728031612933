import React, {FC, ReactElement, useCallback, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import {useAuth0} from "@auth0/auth0-react"
import ky from "ky"
import {MERCURY_URL} from "../../_helpers/environment"
import {FormProps, TypeThing} from "../../_types/types"
import {headers, headersNoCache} from "../../_helpers/auth-header"
import {decodeBase64} from "../../_helpers/encoding"
import LoaderInline from "../../_components/Loader/LoaderInline"
import ErrorHandler from "../Toast/ErrorHandler"
import {ColumnsContainer, FormFooter, FormHeader, HalfColumn} from "./EditorForm"
import queryString from "query-string"

interface EditorPageProps<T> {
    name: string
    initial: T
    path: string
    endpoint: string
    form: (props: FormProps<T>) => ReactElement
}

export const EditorDescription: FC<{ children?: React.ReactNode }> = ({children}) => (
    <span className="form-description">{children}</span>
)

const EditorPage = <T extends TypeThing>({name, path, form, initial, endpoint}: EditorPageProps<T>): ReactElement => {

    const navigate = useNavigate()

    const query = queryString.parse(window.location.search)
    const copyId = (query["copy"] && typeof query["copy"] === "string") ? decodeBase64(query["copy"]) : ""

    const {getAccessTokenSilently} = useAuth0()

    const {"id": urlId} = useParams<{ id: string }>()
    const id = (urlId !== "create") ? decodeBase64(urlId || "") : ""

    const [instance, setInstance] = useState<T>(initial)
    const [error, setError] = useState<any>()
    const [busy, setBusy] = useState(true)
    const [done, setDone] = useState(false)

    useEffect(() => {
        setBusy(true)
        if (id === "" && copyId === "") {
            setBusy(false)
            return
        }
        getAccessTokenSilently()
            .then(t => {
                ky.get(id || copyId, {
                    "headers": headersNoCache(t),
                })
                    .json<T>()
                    .then(result => {
                        if (id === "") {
                            result["@id"] = ""
                            if (result.hasOwnProperty("name")) {
                                //@ts-ignore
                                result["name"] = result["name"] + " Copy"
                            }
                            if (result.hasOwnProperty("identifier")) {
                                //@ts-ignore
                                result["identifier"] = result["identifier"] + "-copy"
                            }
                        }
                        setInstance(result)
                    })
                    .catch(e => setError(e))
                    .finally(() => setBusy(false))
            })
    }, [getAccessTokenSilently, id, copyId])

    const save = useCallback(() => {
        setBusy(true)
        getAccessTokenSilently()
            .then(t => {
                let f = ky.post
                let url = `${MERCURY_URL}${endpoint}`
                if (id !== "") {
                    f = ky.put
                    url = id
                }
                f(url, {"json": instance, "headers": headers(t)})
                    .then(() => setDone(true))
                    .catch(e => setError(e))
                    .finally(() => setBusy(false))
            })
            .catch(e => {
                setError(e)
                setBusy(false)
            })
    }, [endpoint, id, getAccessTokenSilently, instance])

    if (busy) {
        return <LoaderInline/>
    }

    if (done) {
        navigate(path)
        return <LoaderInline/>
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <FormHeader>
                <h1 className="mb-0">{(id === "") ? "Add" : "Modify"} {name}</h1>
            </FormHeader>
            {form({"mod": setInstance, "val": instance})}
            <ColumnsContainer>
                <HalfColumn>
                    <ErrorHandler error={error}/>
                </HalfColumn>
            </ColumnsContainer>
            <FormFooter>
                <button className="btn btn-primary mt-2 mr-2" type="button" onClick={() => save()}>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2"/>
                    {(id === "") ? "Save as New" : "Save Changes"}
                </button>
                <button onClick={() => setDone(true)} className="btn mt-2" type="button">
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2"/>
                    Discard Changes
                </button>
            </FormFooter>
        </form>
    )
}

export default EditorPage
