import {TypeLocalizedLiteral} from "../_types/types"

export const localizedToString = (d: TypeLocalizedLiteral, lang?: string): string => {
    let guess = false
    if (lang === undefined) {
        guess = true
        lang = "en"
    }
    for (let i = 0; i < d.length; i++) {
        if (d[i]["@language"] === lang) {
            return d[i]["@value"]
        }
    }
    if (guess) {
        return localizedToString(d, "")
    }
    return "[empty]"
}
