import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import {
    AgingListPage,
    AgricultureListPage,
    AppellationListPage,
    BottleCapListPage,
    CategoryListPage,
    CountryListPage,
    DiscoveryCaseListPage,
    GrapeListPage,
    ManufacturerListPage,
    WineListPage,
} from "../Listing/Listing"
import {
    AgingEditor,
    AgricultureEditor,
    AppellationEditor,
    BottleCapEditor,
    CategoryEditor,
    CountryEditor,
    DiscoveryCaseEditor,
    GrapeEditor,
    ManufacturerEditor,
    WineEditor,
} from "./ProductEditors"

const ProductRoutes: FC = () => (
    <Routes>
        <Route path="/categories" element={<CategoryListPage/>}/>
        <Route path="/categories/:id" element={<CategoryEditor/>}/>
        <Route path="/grapes" element={<GrapeListPage/>}/>
        <Route path="/grapes/:id" element={<GrapeEditor/>}/>
        <Route path="/agriculture" element={<AgricultureListPage/>}/>
        <Route path="/agriculture/:id" element={<AgricultureEditor/>}/>
        <Route path="/bottle-caps" element={<BottleCapListPage/>}/>
        <Route path="/bottle-caps/:id" element={<BottleCapEditor/>}/>
        <Route path="/agings" element={<AgingListPage/>}/>
        <Route path="/agings/:id" element={<AgingEditor/>}/>
        <Route path="/countries" element={<CountryListPage/>}/>
        <Route path="/countries/:id" element={<CountryEditor/>}/>
        <Route path="/appellations" element={<AppellationListPage/>}/>
        <Route path="/appellations/:id" element={<AppellationEditor/>}/>
        <Route path="/manufacturers" element={<ManufacturerListPage/>}/>
        <Route path="/manufacturers/:id" element={<ManufacturerEditor/>}/>
        <Route path="/wines" element={<WineListPage/>}/>
        <Route path="/wines/:id" element={<WineEditor/>}/>
        <Route path="/discovery-cases" element={<DiscoveryCaseListPage/>}/>
        <Route path="/discovery-cases/:id" element={<DiscoveryCaseEditor/>}/>
    </Routes>
)

export default ProductRoutes
