import React, {FC, useCallback, useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons"
import "./FormDocumentInput.scss"
import FormInput from "./FormInput"
import {getCodes, getName} from "country-list"
import {searchStringToRegex} from "../../_helpers/search"

interface SelectedProps {
    countryCode: string
    onClick: (id: string) => void
}

const SelectedDocument: FC<SelectedProps> = ({countryCode, onClick}) => (
    <tr>
        <td>{getName(countryCode)}</td>
        <td>
            <button
                className="btn btn-circle btn-red"
                type="button"
                onClick={() => onClick(countryCode)}
            >
                <FontAwesomeIcon icon={faMinus}/>
            </button>
        </td>
    </tr>
)

interface CountryRowListItemProps {
    visible: boolean
    included: boolean
    code: string
    onRemove: (v: string) => void
    onAdd: (v: string) => void
}

const CountryRowListItem: FC<CountryRowListItemProps> = React.memo(({visible, code, included, onAdd, onRemove}) => (
    <tr style={{"display": visible ? "" : "none"}}>
        <td>
            {getName(code)}
        </td>
        <td>
            {included ? (
                <button
                    className="btn btn-circle btn-red"
                    type="button"
                    onClick={() => onRemove(code)}
                >
                    <FontAwesomeIcon icon={faMinus}/>
                </button>
            ) : (
                <button className="btn btn-circle btn-green" type="button" onClick={() => onAdd(code)}>
                    <FontAwesomeIcon icon={faPlus}/>
                </button>
            )}
        </td>
    </tr>
))

export interface PropsSelectMulti {
    value: string[]
    onChange: (v: string[]) => void
}

export const FormCountriesInput: FC<PropsSelectMulti> = ({value, onChange}) => {

    const [search, setSearch] = useState<string>("")
    const [visible, setVisible] = useState<string[]>([])
    const countries = getCodes().sort((a: string, b: string) => {
        const as = getName(a) || ""
        const bs = getName(b) || ""
        return as < bs ? -1 : 1
    })

    useEffect(() => {
        const filtered: string[] = []
        const query = searchStringToRegex(search)
        getCodes().forEach(code => {
            if (search === "") {
                filtered.push(code)
                return
            }
            const name = getName(code)
            if (name === undefined) {
                return
            }
            if (query.test(name.toLowerCase())) {
                filtered.push(code)
                return
            }
        })
        setVisible(filtered)
    }, [search])

    const onRemove = useCallback((id: string) => {
        onChange(value.filter(s => s !== id))
    }, [onChange, value])

    const onAdd = useCallback((id: string) => {
        onChange([...value, id])
    }, [onChange, value])

    return (
        <div>
            {value.length > 0 ? (
                <>
                    <table className="form-input-document">
                        <tbody>
                        {value.map(s => (
                            <SelectedDocument
                                key={s}
                                countryCode={s}
                                onClick={onRemove}
                            />
                        ))}
                        </tbody>
                    </table>
                    <div className="divider"/>
                </>
            ) : null}
            <div className="mb-2">
                <FormInput
                    placeholder={"Type to search"}
                    value={search}
                    onChange={v => setSearch(v)}
                />
            </div>
            <div className="input-countries">
                <table className="form-input-document">
                    <tbody>
                    {countries.length === 0 ? (
                        <tr>
                            {search !== "" ? <td className="py-2 text-small">No documents found</td> : null}
                            <td/>
                        </tr>
                    ) : null}
                    {countries.map(code => {
                        let included = value.includes(code)
                        let display = visible.includes(code)
                        return <CountryRowListItem key={code} visible={display} code={code} onAdd={onAdd}
                                                   included={included} onRemove={onRemove}/>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
