import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import HorecaMemberListPage from "./HorecaMemberListPage"
import OrderListPage from "../Transactional/Order/OrderListPage"
import OrderPage from "../Transactional/Order/OrderPage"

const HorecaRoutes: FC = () => (
    <Routes>
        <Route path="/members" element={<HorecaMemberListPage/>}/>
        <Route path="/orders" element={<OrderListPage isHoreca={true}/>}/>
        <Route path="/orders/:id" element={<OrderPage isHoreca={true}/>}/>
    </Routes>
)

export default HorecaRoutes
