import ky, {Options, ResponsePromise} from "ky"
import {headersNoCache} from "../_helpers/auth-header"
import {Input} from "ky/distribution/types/options"

type TokenGetter = () => Promise<string>

const makeRequest = async (url: string, opts: Options, f: (url: Input, options?: Options) => ResponsePromise, getToken: TokenGetter): Promise<Response> => {
    const t = await getToken()
    return f(url, {...opts, "headers": headersNoCache(t)})
}

const makeRequestJson = async <T extends unknown>(url: string, opts: Options, f: (url: Input, options?: Options) => ResponsePromise, getToken: TokenGetter): Promise<T> => {
    const t = await getToken()
    return f(url, {...opts, "headers": headersNoCache(t)}).json<T>()
}

export const doGet = (url: string, getToken: TokenGetter): Promise<Response> => {
    return makeRequest(url, {}, ky.get, getToken)
}

export const doPut = (url: string, getToken: TokenGetter): Promise<Response> => {
    return makeRequest(url, {}, ky.put, getToken)
}

export const doDelete = (url: string, getToken: TokenGetter): Promise<Response> => {
    return makeRequest(url, {}, ky.delete, getToken)
}

export const doPost = (url: string, opts: Options, getToken: TokenGetter): Promise<Response> => {
    return makeRequest(url, opts, ky.post, getToken)
}

export const doGetJson = <T extends unknown>(url: string, getToken: TokenGetter): Promise<T> => {
    return makeRequestJson<T>(url, {}, ky.get, getToken)
}

export const doPostJson = <T extends unknown>(url: string, getToken: TokenGetter): Promise<T> => {
    return makeRequestJson<T>(url, {}, ky.post, getToken)
}
