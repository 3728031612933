import React, {FC, useCallback, useEffect, useState} from "react"
import {Link, useNavigate, useParams} from "react-router-dom"
import ky from "ky"
import "./NamespaceEditorPage.scss"
import TextareaAutosize from "react-textarea-autosize"
import Loader from "../../_components/Loader/Loader"
import {API_URL, MERCURY_URL} from "../../_helpers/environment"
import {headersNoCache} from "../../_helpers/auth-header"
import {useAuth0} from "@auth0/auth0-react"
import {FormWrapper, InputDivider} from "../../_components/Editor/EditorForm"
import {doGetJson} from "../../_services/requests.service"

interface ResourceBundle {
    [key: string]: string
}

interface Language {
    name: string,
    alternateName: string
}

interface LanguagesResponse {
    "@graph": Language[]
}

interface KeyLanguageRowProps {
    value?: string,
    onChange: (v: string) => void,
    language: Language
}

const KeyLanguageRow: FC<KeyLanguageRowProps> = React.memo(({language, onChange, value}) => (
    <div className="columns key-language">
        <div className="column col-auto key-language-code">
            <div className="key-language-name">
                {language.alternateName}
            </div>
        </div>
        <div className="column">
            <TextareaAutosize
                className="form-input"
                rows={1}
                value={value || ""}
                onChange={e => onChange(e.target.value)}
            />
        </div>
    </div>
), ((prevProps, nextProps) => {
    return prevProps.value === nextProps.value &&
        prevProps.language.alternateName === nextProps.language.alternateName
}))

const NamespaceEditorPage: FC = () => {

    const {ns} = useParams<{ ns: string }>()
    const navigate = useNavigate()
    const {getAccessTokenSilently} = useAuth0()
    const [busy, setBusy] = useState<number>(0)
    const [languages, setLanguages] = useState<Language[]>([])
    const [bundles, setBundles] = useState<{ [key: string]: ResourceBundle }>({})
    const [error, setError] = useState<any>()
    const [keys, setKeys] = useState<string[]>([])
    const [search, setSearch] = useState<string>("")

    useEffect(() => {
        setBusy(prev => prev + 1)
        doGetJson<LanguagesResponse>(`${MERCURY_URL}/languages?page[size]=10`, getAccessTokenSilently)
            .then(list => list["@graph"])
            .then(languages => setLanguages(languages))
            .catch(error => setError(error))
            .finally(() => setBusy(prev => prev - 1))
    }, [getAccessTokenSilently, ns])

    useEffect(() => {
        setBusy(prev => prev + 1)
        Promise.all(languages.map(language => {
            return ky.get(`${API_URL}/locales/${language.alternateName}/${ns}`, {"headers": headersNoCache()})
                .json<ResourceBundle>()
                .then(bundle => ({[language.alternateName]: bundle}))
        }))
            .then(objects => {
                const keys = new Set<string>()
                const bundles: { [key: string]: ResourceBundle } = {}
                objects.forEach(object => Object.assign(bundles, object))
                for (let key in bundles) Object.keys(bundles[key]).forEach(key => keys.add(key))
                setBundles(bundles)
                setKeys(Array.from(keys.values()))
            })
            .catch(error => setError(error))
            .finally(() => setBusy(prev => prev - 1))
    }, [languages, ns])

    const submit = useCallback(() => {
        setBusy(prev => prev + 1)
        getAccessTokenSilently()
            .then(t => {
                Promise.all(languages.map(language => {
                    return ky.put(`${API_URL}/locales/${language.alternateName}/${ns}`, {
                        "json": bundles[language.alternateName],
                        "headers": headersNoCache(t),
                    })
                }))
                    .then(() => navigate("/localization/translations"))
                    .catch(error => setError(error))
                    .finally(() => setBusy(prev => prev - 1))
            })
            .catch(e => {
                setError(e)
                setBusy(prev => prev - 1)
            })
    }, [navigate, ns, bundles, languages, getAccessTokenSilently])

    if (busy > 0) return <Loader/>

    let index = 0

    return (
        <div className='page-document' id="namespace">
            <form
                onSubmit={e => {
                    e.preventDefault()
                    submit()
                }}
            >
                <div className='page-header'>
                    <h1 style={{"textTransform": "capitalize"}}>{ns}</h1>
                    <div className='page-actions'>
                        <button type="submit" className="btn btn-primary mr-2">Save</button>
                        <Link to={"/localization/translations"} className="btn btn-secondary">Discard</Link>
                    </div>
                </div>
                <div className='page-body'>
                    {error ? (
                        <div className="toast toast-error">
                            Could not retrieve localization bundle. <br/>
                            {error.toString()}
                        </div>
                    ) : null}
                    <div className="form-group" style={{"marginBottom": "1rem"}}>
                        <label className="form-label" htmlFor="search">Search for key</label>
                        <input
                            placeholder="e.g. btn, addToCart, signIn"
                            autoComplete="off"
                            id="search"
                            type="text"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            className="form-input"
                        />
                    </div>
                    <FormWrapper>
                        {keys.map((key) => {
                            let visible = true
                            if (search !== "") {
                                if (key.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                                    visible = false
                                }
                            }
                            if (visible) {
                                index++
                            }
                            return (
                                <div key={`row.${key}`}>
                                    {(index !== 1 && visible) ? <InputDivider/> : null}
                                    <div
                                        className="key-container"
                                        style={{"display": visible ? "block" : "none"}}
                                    >
                                        <div className="key-name">{key}</div>
                                        <div>
                                            {languages?.map(language => {
                                                return (
                                                    <KeyLanguageRow
                                                        key={key + "." + language.alternateName}
                                                        language={language}
                                                        value={bundles[language.alternateName][key]}
                                                        onChange={v => {
                                                            setBundles(prev => ({
                                                                ...prev,
                                                                [language.alternateName]: {
                                                                    ...prev[language.alternateName],
                                                                    [key]: v,
                                                                },
                                                            }))
                                                        }}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </FormWrapper>
                </div>
            </form>
        </div>
    )
}

export default NamespaceEditorPage
