import React, {FC} from "react"
import EditorPage, {EditorDescription} from "../_components/Editor/EditorPage"
import {TypeArticle, TypeCategory, TypeWikiArticle, TypeWine, TypeWineList} from "../_types/types"
import {
    ColumnsContainer,
    FormWrapper,
    HalfColumn,
    InputContainer,
    InputDivider,
    InputLabel,
} from "../_components/Editor/EditorForm"
import FormInput from "../_components/Input/FormInput"
import {
    EditorMultiDocumentSelect,
    EditorSingleDocumentSelect,
    SingleImageContainer,
} from "../_components/Editor/EditorInputs"
import FormLocalizedInput from "../_components/Input/FormLocalizedInput"
import {localizedToString} from "../_helpers/localiztion"
import {toIdentifier} from "../_helpers/identifiers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"

export const ArticleEditor: FC = () => (
    <EditorPage<TypeArticle>
        name={"Article"}
        path={"/content/articles"}
        endpoint={"/articles"}
        initial={{
            "@id": "",
            "@type": "",
            "articleBody": [],
            "identifier": "",
            "author": {"name": ""},
            "callToAction": [],
            "datePublished": {"dateModified": "", "datePublished": ""},
            "description": [],
            "headline": [],
            "image": null,
            "keywords": [],
            "name": "",
            "publisher": {"name": ""},
            "wineList": null,
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <SingleImageContainer
                        title={"Banner"}
                        value={val.image}
                        onChange={v => mod(p => ({...p, "image": v}))}
                        fullSize={"2000x800"}
                        thumbSize={"600x400"}
                    />
                    <FormWrapper>
                        <InputLabel title={"Article Content"}/>
                        <FormLocalizedInput
                            placeholder={"# The"}
                            minRows={10}
                            textarea={true}
                            value={val.articleBody}
                            onChange={v => mod(p => ({...p, "articleBody": v}))}
                        />
                        <EditorDescription>
                            Include a H1 title using a "#".
                        </EditorDescription>
                    </FormWrapper>
                </HalfColumn>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                id={"inputName"}
                                value={val.name}
                                type={"text"}
                                onChange={v => mod(p => ({...p, "name": v, "identifier": toIdentifier(v)}))}
                            />
                            <EditorDescription>
                                Only used internally.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                disabled={true}
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={() => null}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Author"}>
                            <FormInput
                                value={val.author.name}
                                onChange={v => mod(p => ({...p, "author": {...p.author, "name": v}}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Publish Date"}>
                            <FormInput
                                type={"date"}
                                value={val.datePublished.datePublished}
                                onChange={v => mod(p => ({
                                    ...p,
                                    "datePublished": {...p.datePublished, "datePublished": v},
                                }))}
                            />
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Headline"}>
                            <FormLocalizedInput
                                value={val.headline}
                                onChange={v => mod(p => ({...p, "headline": v}))}
                            />
                            <EditorDescription>
                                Used in banner and can be used as title in search engines.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Description"}>
                            <FormLocalizedInput
                                textarea={true}
                                minRows={3}
                                value={val.description}
                                onChange={v => mod(p => ({...p, "description": v}))}
                            />
                            <EditorDescription>
                                Description can appear in search engines as snippet of article.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Keywords"}>
                            <FormLocalizedInput
                                value={val.keywords}
                                onChange={v => mod(p => ({...p, "keywords": v}))}
                            />
                            <EditorDescription>
                                Used for discoverability, but Google ignores this.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                    <FormWrapper>
                        <InputContainer title={"Call To Action"}>
                            <FormLocalizedInput
                                value={val.callToAction}
                                onChange={v => mod(p => ({...p, "callToAction": v}))}
                            />
                            <EditorDescription>
                                Can contain markdown, useful to place button at bottom of page.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeWineList>
                            value={val.wineList}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "wineList": v}))}
                            endpoint={"/wine-lists"}
                            filterBy={"name.@value"}
                            name={"Wine Showcase"}
                        />
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)


export const WikiArticleEditor: FC = () => (
    <EditorPage<TypeWikiArticle>
        name={"Article"}
        path={"/content/wiki-articles"}
        endpoint={"/wiki-articles"}
        initial={{
            "@id": "",
            "@type": "",
            "articleBody": [],
            "identifier": "",
            "headline": [],
            "name": "",
            "category": null,
            "publisher": {"name": "Matthys Wines"},
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Title"}>
                            <FormLocalizedInput
                                value={val.headline}
                                onChange={v => mod(p => ({...p, "headline": v}))}
                            />
                        </InputContainer>
                        <InputLabel title={"Article Content"}/>
                        <FormLocalizedInput
                            placeholder={"# The"}
                            minRows={10}
                            textarea={true}
                            value={val.articleBody}
                            onChange={v => mod(p => ({...p, "articleBody": v}))}
                        />
                        <EditorDescription>
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1"/> Include a H1 title using a
                            "#".
                            <br/>
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1"/> Do not use "default" when
                            there is only
                            english text as Google will otherwise assign a random language to this page.
                        </EditorDescription>
                    </FormWrapper>
                </HalfColumn>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormInput
                                id={"inputName"}
                                value={val.name}
                                type={"text"}
                                onChange={v => mod(p => ({...p, "name": v, "identifier": toIdentifier(v)}))}
                            />
                            <EditorDescription>
                                Only used internally.
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                disabled={true}
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={() => null}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeCategory>
                            value={val.category}
                            filterBy={"name.@value"}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "category": v}))}
                            endpoint={"/wiki-categories"}
                            name={"Category"}
                        />
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

export const WineListEditor: FC = () => (
    <EditorPage<TypeWineList>
        name={"Wine List"}
        path={"/content/wine-lists"}
        endpoint={"/wine-lists"}
        initial={{
            "@id": "",
            "@type": "",
            "description": [],
            "identifier": "",
            "itemListElement": [],
            "name": [],
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                            <EditorDescription>
                                Used to reference wine lists like "newArrivals".
                            </EditorDescription>
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Lead"}>
                            <FormLocalizedInput
                                value={val.description}
                                onChange={v => mod(p => ({...p, "description": v}))}
                            />
                            <EditorDescription>
                                Optional short text that will be placed under the name. Leave this blank if unused.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
                <HalfColumn>
                    <FormWrapper>
                        <EditorMultiDocumentSelect<TypeWine>
                            value={val.itemListElement}
                            getName={d => d.name}
                            onChange={v => mod(p => ({...p, "itemListElement": v}))}
                            endpoint={"/wines"}
                            filterBy={"name"}
                            name={"Wines"}
                        />
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)


export const WikiCategoryEditor: FC = () => (
    <EditorPage<TypeCategory>
        name={"Wiki Category"}
        path={"/content/wiki-categories"}
        endpoint={"/wiki-categories"}
        initial={{
            "@id": "",
            "@type": "",
            "name": [],
            "parent": null,
            "identifier": "",
            "order": 0,
        }}
        form={({mod, val}) => (
            <ColumnsContainer>
                <HalfColumn>
                    <FormWrapper>
                        <InputContainer title={"Name"}>
                            <FormLocalizedInput
                                id={"inputName"}
                                value={val.name}
                                onChange={v => mod(p => ({...p, "name": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <EditorSingleDocumentSelect<TypeCategory>
                            value={val.parent}
                            filterBy={"name.@value"}
                            getName={d => localizedToString(d.name)}
                            onChange={v => mod(p => ({...p, "parent": v}))}
                            endpoint={"/wiki-categories"}
                            name={"Parent Category"}
                        />
                        <InputDivider/>
                        <InputContainer title={"Identifier"}>
                            <FormInput
                                type={"text"}
                                id={"inputIdentifier"}
                                value={val.identifier}
                                onChange={v => mod(p => ({...p, "identifier": v}))}
                            />
                        </InputContainer>
                        <InputDivider/>
                        <InputContainer title={"Rank"}>
                            <FormInput
                                type={"number"}
                                id={"inputOrder"}
                                value={val.order}
                                onChange={v => mod(p => ({...p, "order": v}))}
                            />
                            <EditorDescription>
                                Listings are sorted using this number from low to high.
                            </EditorDescription>
                        </InputContainer>
                    </FormWrapper>
                </HalfColumn>
            </ColumnsContainer>
        )}
    />
)

