import queryString from "query-string"
import {decodeBase64} from "../_helpers/encoding"
import {NavigateFunction} from "react-router-dom"

export const onAuthenticatedCallback: (navigate: NavigateFunction) => void = (navigate) => {
    const query = queryString.parse(window.location.search)

    // Do redirect to previous flow
    let path = "/"
    if (typeof query["r"] === "string") {
        path = decodeBase64(query["r"])
        delete query["r"]
    }

    delete query["state"]
    delete query["code"]

    // Set correct language
    navigate(`${path}?${queryString.stringify(query)}`)
}
