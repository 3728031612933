import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import {ArticleEditor, WikiArticleEditor, WikiCategoryEditor, WineListEditor} from "./ContentEditors"
import {ArticlesListPage, WikiArticlesListPage, WikiCategoryListPage, WineListsListPage} from "../Listing/Listing"

const ContentRoutes: FC = () => (
    <Routes>
        <Route path="/articles" element={<ArticlesListPage/>}/>
        <Route path="/articles/:id" element={<ArticleEditor/>}/>
        <Route path="/wine-lists" element={<WineListsListPage/>}/>
        <Route path="/wine-lists/:id" element={<WineListEditor/>}/>
        <Route path="/wiki-articles" element={<WikiArticlesListPage/>}/>
        <Route path="/wiki-articles/:id" element={<WikiArticleEditor/>}/>
        <Route path="/wiki-categories" element={<WikiCategoryListPage/>}/>
        <Route path="/wiki-categories/:id" element={<WikiCategoryEditor/>}/>
    </Routes>
)

export default ContentRoutes
