import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import SubscriptionListPage from "./Subscriptions/SubscriptionListPage"

const CustomersRoutes: FC = () => (
    <Routes>
        <Route path="/subscriptions" element={<SubscriptionListPage/>}/>
    </Routes>
)

export default CustomersRoutes
